import React, { Component } from "react"

/* Components */
import { Block } from "../components"

/* Widgets */
import CategoryDetail from "../widgets/Category/CategoryDetail"

import { view } from "../api/Category"

/* Redux */
import { connect } from 'react-redux'

/* Helpers */
import { withRouter } from '../helpers'


/* Page My Orders */
class Category extends Component {

    constructor() {
        super()
        this.state = {
            data: [],
            loading: false,
            network: false,
            error: false
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load Data */
    load = () => {
        const { slug } = this.props.params
        const { city } = this.props
        const data = {
            slug,
            cityID: city.id
        }
        view(data).then(response => {
            if (response.status === 200) {
                this.setState({ data: response.data.restaurants, network: false, error: false })
            }
            else {
                this.setState({ error: true })
            }
        }).catch(() => {
            this.setState({ network: true })
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render = () => {
        const { data } = this.state
        return (
            <Block>
                <CategoryDetail restaurants={data} />
            </Block>
        )
    }

}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        token: state.token,
        city: state.city
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Category))