import React, { Component } from 'react'
import {utils} from "../../helpers";




/* Widget Order Detail */
class OrderDetail extends  Component {

    constructor() {
        super();

        this.state = {
            addressID: 0,
        }
    }

    render = () => {

        const { data, setDelivery, addresses, openAddressModal, setAddress } = this.props

        return (
            <div className="order-restaurant">

                <div className="order-restaurant-title">{data.name}</div>

                <div className="order-restaurant-row">

                    <div className="order-restaurant-box">

                        <div className="order-delivery-types">
                            <div onClick={() => setDelivery(data.id, "delivery")}
                                 className={data.deliveryType === 'delivery' ? `order-delivery-type-active` : 'order-delivery-type'}>Курьером
                            </div>
                            <div onClick={() => setDelivery(data.id, "pickup")}
                                 className={data.deliveryType === 'pickup' ? `order-delivery-type-active` : 'order-delivery-type'}>Самовывоз
                            </div>
                        </div>

                        {data.deliveryType === 'delivery' &&
                            <div className="order-form-row">
                                <h3>Адрес доставки</h3>

                                <div className="order-address">

                                    {addresses.map((item, index) =>
                                        <div onClick={() => setAddress(data.id, item)} key={`${index}`} className="order-address-item">
                                            {(data.address && data.address.id && parseInt(data.address.id) === parseInt(item.id)) ? <div className="checked"><div className="checked-inner" /></div> : <div className="unchecked" />}
                                            <span>{item.address}</span>
                                        </div>
                                    )}

                                     <div onClick={() => openAddressModal()} className="order-address-add">Добавить адрес</div>

                                </div>

                            </div>
                        }


                    </div>

                    {/* PRODUCTS */}
                    <div className="order-products">
                        {data.products.map((item, index) =>
                            <div key={`${index}`} className="order-product-row">
                                <p className="first">{item.name}</p>
                                <p>{item.amount}&nbsp;x&nbsp;{utils.convertor(item.price)}</p>
                            </div>
                        )}
                    </div>


                </div>
            </div>

        )
    }

}

export default OrderDetail