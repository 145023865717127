import React, { Component } from "react"
import { Restaurant } from "../Restaurant";

/* Components */
import { Empty } from '../../components'


class CategoryDetail extends Component {

    render = () => {
        const { restaurants } = this.props

        
        if (restaurants.length === 0) {
            return (
                <div className="restaurants-container">
                    <div className="restaurants-wrapper">
                        <Empty page="restaurants" />
                    </div>
                </div>
            )
        }

        return (
            <div>
                <div className="restaurants-container">
                    <div className="restaurants-wrapper">
                        <h2>Category</h2>
                        <div className="restaurants">
                            {restaurants.map((item, index) =>
                                <Restaurant key={`${index}`} data={item} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}


export default CategoryDetail