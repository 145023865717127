/* Cart REDUX */

const cartJSON = localStorage.getItem("cart")
const DEFAULT = cartJSON ? JSON.parse(cartJSON) : []

const cart = (state = DEFAULT, action) => {

    switch (action.type) {

        case 'ADD_TO_CART':
            const {restaurant, product} = action.payload

            let created = [...state]

            const createIndex = state.findIndex(e => parseInt(e.id) === parseInt(restaurant.id))

            if (createIndex > -1) {
                created[createIndex] = {...restaurant, products: [...state[createIndex].products, product]}
            } else {
                created = [...state, {...restaurant, products: [product]}]
            }

            localStorage.setItem("cart", JSON.stringify(created))
            return created

        case 'REMOVE_FROM_CART':
            const {removeRestaurant, removeProduct} = action.payload;

            const removed = state
                .map(restaurant =>
                    restaurant.id === removeRestaurant.id
                        ? {
                            ...restaurant,
                            products: restaurant.products.filter(product => product.id !== removeProduct.id),
                        }
                        : restaurant
                )
                .filter(restaurant => restaurant.products.length > 0);

            localStorage.setItem("cart", JSON.stringify(removed));
            return removed;

        case 'UPDATE_DATA':
            const {updateRestaurant, updateProduct} = action.payload;

            const updated = state.map(restaurant =>
                restaurant.id === updateRestaurant.id
                    ? {
                        ...restaurant,
                        products: restaurant.products.map(product =>
                            product.id === updateProduct.id
                                ? {...product, amount: updateProduct.amount}
                                : product
                        ),
                    }
                    : restaurant
            );

            localStorage.setItem("cart", JSON.stringify(updated));
            return updated;

        case 'REFRESH_DATA':
            const refreshed = [...state]
            const index = state.findIndex(item => item.id === action.payload.id)

            if (index > -1) {
                refreshed[index] = action.payload
            }

            localStorage.setItem("cart", JSON.stringify(refreshed))
            return refreshed

        case 'CLEAR_RESTAURANT':
            const { clearRestaurant } = action.payload;
            // Filter out the specified restaurant from the state
            const cleared = state.filter(restaurant => restaurant.id !== clearRestaurant.id);

            // Update the 'cart' data in local storage
            localStorage.setItem("cart", JSON.stringify(cleared));

            // Return the updated state with the specified restaurant removed
            return cleared;

        case 'CLEAR_CART':
            localStorage.removeItem("cart")
            return []

        default:
            return state
    }

}

export default cart