import React, { Component } from "react"



/* Component Text */
class Text extends Component {

    render = () => {

        const {
            size,
            regular, medium, bold,
            color,
            center, lineTrought, undeline,
            top, bottom, left, right,
            style, children, ...props
        } = this.props

        let fontSize = 14
        let fontWeight = 400
        let textColor = '#202020'
        let paddingTop = 0
        let paddingBottom = 0
        let paddingLeft = 0
        let paddingRight = 0
        let textAlign = 'start'
        let textDecorationLine = 'none'

        if (size) {
            fontSize = size
        }

        if (regular) {
            fontWeight = 400
        }

        if (medium) {
            fontWeight = 500
        }

        if (bold) {
            fontWeight = 600
        }

        if (color) {
            textColor = color
        }

        if (top) {
            paddingTop = top
        }

        if (bottom) {
            paddingBottom = bottom
        }

        if (left) {
            paddingLeft = left
        }

        if (right) {
            paddingRight = right
        }

        if (center) {
            textAlign = 'center'
        }

        if (lineTrought) {
            textDecorationLine = 'line-through'
        }

        if (undeline) {
            textDecorationLine = 'underline'
        }


        const styles = {
            fontSize,
            fontWeight,
            color: textColor,
            paddingTop,
            paddingBottom,
            paddingLeft,
            paddingRight,
            textAlign,
            textDecorationLine,
            display: 'inline-block'
        }


        return <div style={styles} {...props}>{children}</div>

    }

}

export default Text