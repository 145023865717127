import React, { useEffect, useState } from "react"

/* Components */
import { Text } from '../../components'

/* Swipeable */
import { useSwipeable } from 'react-swipeable'

/* React Router */
import { Link } from "react-router-dom"

/* Constants */
import { env } from '../../constants'


/* Constants */
const WRAPPER = 1220
const MULTIPLIER = 2.25


/* Widget Slide */
const Slide = ({ data }) => {


    /* Hooks */
    const [width, setWidth] = useState(window.innerWidth)
    const [index, setIndex] = useState(0)
    const [paused, setPaused] = useState(false)


    /* Swipe Handlers */
    const handlers = useSwipeable({
        onSwipedLeft: () => updateIndex(index + 1, width),
        onSwipedRight: () => updateIndex(index - 1, width),
    })


    /* Window Resize Events and Auto Play */
    useEffect(() => {

        const interval = setInterval(() => {
            if (!paused) {
                updateIndex(index + 1, width)
            }
        }, 5000)

        window.addEventListener('resize', updateWindowDimensions)

        return () => {

            if (interval) {
                clearInterval(interval)
            }

            window.removeEventListener('resize', updateWindowDimensions)
        }

    })


    /* Window Resize Handler */
    const updateWindowDimensions = () => {
        setWidth(window.innerWidth)
    }


    /* Change Carousel Index */
    const updateIndex = (i, screenWidth) => {

        if (Array.isArray(data) && data.length > 2) {

            if (screenWidth <= 768) {
                if (i < 0) {
                    i = data.length - 1
                }
                else if (i >= data.length) {
                    i = 0
                }
            }
            else {
                if (i < 0) {
                    i = data.length - 2
                }
                else if (i > data.length - 2) {
                    i = 0
                }
            }

            setIndex(i)

        }
    }


    let start = 0
    let carouselWidth = width / 2

    if (width > WRAPPER) {
        carouselWidth = WRAPPER / 2
        start = (width - WRAPPER) / 2
    }

    if (width <= 768) {
        carouselWidth = width - 40
        start = 20
    }

    return (
        <div className="home-slide" {...handlers} onMouseEnter={() => setPaused(true)} onMouseLeave={() => setPaused(false)}>

            <div className="home-slide-inner" style={{ transform: `translateX(${start - carouselWidth * index}px)` }} >
                {Array.isArray(data) && data.length > 0 && data.map((item, index) =>
                    <div className="home-slide-item" style={{ width: carouselWidth, height: carouselWidth / MULTIPLIER }} key={`${index}`}>
                        <Link to={`${item.link}`} className="home-slide-item-inner">
                            {item.image ? <img src={`${env.mediapoint}slides/${item.image}`} alt="Slide" /> : null}
                            <div className="home-slide-item-shadow" />
                            <div className="home-slide-item-text">
                                <Text size={22} color="white" bold>{item.title}</Text>
                                <Text size={13} color="white">{item.text}</Text>
                            </div>
                        </Link>
                    </div>
                )}
            </div>

            {Array.isArray(data) && data.length > 2 &&
                <div onClick={() => updateIndex(index + 1, width)} className="home-slide-arrow" style={{ top: carouselWidth / (MULTIPLIER * 2) - 10, right: start - 5 }}>
                    <img src="/images/arrowleft.png" alt="Arrow" />
                </div>
            }

            {Array.isArray(data) && data.length > 2 &&
                <div onClick={() => updateIndex(index - 1, width)} className="home-slide-arrow" style={{ top: carouselWidth / (MULTIPLIER * 2) - 10, left: start - 5 }}>
                    <img src="/images/arrowright.png" alt="Arrow" />
                </div>
            }

        </div>
    )

}


export default Slide