import { configureStore } from '@reduxjs/toolkit'

/* Reducers */
import auth from './auth'
import token from './token'
import favourites from './favourites'
import cities from './cities'
import city from './city'
import cart from './cart'
import cityModal from './cityModal'


/* Actions */
import { actionCity, actionFavourites } from './actions'


const store = configureStore({
    reducer: { auth, token, favourites, cities, city, cityModal, cart }
})

actionFavourites(store)
actionCity(store)

export { store }