import React, { Component } from "react"

/* Components */
import { Block, Loading, Network } from '../components'

/* Widgets */
import { Category, Restaurants } from '../widgets/Home'

/* REST API */
import {search} from '../api/Home'

/* Redux */
import { connect } from 'react-redux'

/* Helpers */
import { withRouter } from '../helpers'

/* Page Home */
class Search extends Component {

    constructor() {
        super()

        this.state = {
            data: null,
            loading: true,
            error: false,
            network: false
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load Data */
    load = () => {
        const { city } = this.props
        const { slug } = this.props.params

        const data = {
            cityID: city.id,
            slug
        }

        search(data).then(response => {
            if (response.status === 200) {
                this.setState({ data: response.data, network: false, error: false })
            }
            else {
                this.setState({ error: true })
            }
        }).catch(() => {
            this.setState({ network: true })
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render = () => {

        const { loading, network, error, data } = this.state

        if (loading) {
            return (
                <Block>
                    <Loading />
                </Block>
            )
        }

        if (network) {
            return (
                <Block>
                    <Network reload={() => this.load()} />
                </Block>
            )
        }

        if (error || !data) {
            return (
                <Block>
                    <Network error reload={() => this.load()} />
                </Block>
            )
        }

        return (
            <Block>

                <Category data={data.categories} />
                <Restaurants title="Результаты" data={data.restaurants} />

            </Block>
        )
    }

}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        token: state.token,
        city: state.city
    }
}

export default connect(mapStateToProps)(withRouter(Search))