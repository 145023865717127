import React, { Component } from "react"

/* React Router */
import { Link } from 'react-router-dom'

/* Constants */
import { env } from '../../constants'

/* Widget Category */
class Category extends Component {

    render = () => {

        const { data } = this.props
        
        return (
            <div className="home-categories">
                <div className="home-categories-wrapper">

                    {data.map((item, index) =>
                        <Link to={`/category/${item.slug}`} key={`${index}`} className="home-category-item">
                            {item.image ? <img src={`${env.mediapoint}categories/${item.image}`} alt={item.text} /> : null}
                            <span>{item.name}</span>
                        </Link>
                    )}

                </div>
            </div>
        )
    }
}

export default Category