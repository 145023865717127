import React, { Component } from "react"

/* React Router */
import { Link } from "react-router-dom"

/* Components */
import { Text } from '../components'

/* Redux */
import { connect } from 'react-redux'

/* JWT */
import { isExpired, decodeToken } from "react-jwt"

/* Helpers */
import { withRouter } from '../helpers'

/* Constants */
import { env } from '../constants'

/* REST API */
import { search } from '../api/Home'

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'


/* Widget Header */
class Header extends Component {

    constructor(props) {
        super(props)

        const { text } = props
        let search = ''

        if (text) {
            search = text
        }

        this.state = {
            search,
            loading: false
        }

        this._timeout = 0
    }

    componentDidMount = () => {
        window.addEventListener("scroll", this.sticky)
    }

    componentWillUnmount = () => {
        window.removeEventListener("scroll", this.sticky)
    }


    /* Scroll Event Listener */
    sticky = () => {
        const header = document.querySelector('.header-box')
        const top = document.querySelector('.header-top')
        const scrollTop = window.scrollY
        scrollTop >= 40 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky')
        scrollTop >= 40 ? top.classList.add('header-big') : top.classList.remove('header-big')
    }


    /* Go To User */
    goUser = () => {

        const { token, toggle, navigate } = this.props

        if (!token || isExpired(token)) {
            toggle(true)
        }
        else {
            navigate('/profile')
        }

    }

    /* Go To Cart */
    goCart = () => {
        const { cart, navigate } = this.props

        if (cart.length > 0) {
            navigate("/cart")
        }
    }

    /* Go To Favourite */
    goFavourite = () => {
        const { token, toggle, navigate } = this.props

        if (!token || isExpired(token)) {
            toggle(true)
        }
        else {
            navigate('/favourite')
        }
    }

    /* Go To Search */
    goSearch = () => {
        const { navigate } = this.props
        const { search } = this.state

        if (search !== "") {
            navigate(`/search/${search}`)
        }
    }

    /* Search */
    search = text => {
        const { city } = this.props
        this.setState({ search: text })

        if (text !== "") {

            this.setState({ loading: true })

            if (this._timeout) {
                clearTimeout(this._timeout)
            }

            this._timeout = setTimeout(() => {

                search({ slug: text, cityID: city.id }).then(response => {
                    if (response.status === 200) {
                        this.setState({ result: response.data })
                    }
                }).finally(() => {
                    this.setState({ loading: false })
                })

            }, 1000)

        }
    }

    _result = () => {

        const { loading, result, search } = this.state
        const { navigate, isSearchPage } = this.props

        if (search !== "" && !isSearchPage) {

            return (
                <div className="header-search-result">
                    {loading && <LoadingOutlined />}
                    {(loading === false && result.restaurants.length === 0) && <p>По вашему запросу ничего не найдено</p>}
                    {(loading === false && result.restaurants.length > 0) && result.restaurants.map((restaurant, index) => {

                        if (index <= 4) {
                            return (
                                <div onClick={() => navigate(`/restaurant/${restaurant.slug}`)} className="header-search-result-item" key={`${index}`}>
                                    {restaurant.name ? <div className="header-search-result-item-name">{restaurant.name}</div> : null}
                                    {restaurant.tagline ? <div className="header-search-result-item-code">Описание: {restaurant.tagline}</div> : null}
                                </div>
                            )
                        }

                        return null
                    })}
                </div>
            )
        }

    }

    /* Draw Address part */
    _address = (isMobile = false) => {

        const { toggleCity, city } = this.props

        if (city) {
            return (
                <div onClick={() => toggleCity(true)} className={isMobile ? "mobile-header-address" : "header-address"}>

                    <div className="header-address-image">
                        <img src="/images/location1.png" alt="Location" />
                    </div>

                    <div className="header-address-text">
                        <Text color={isMobile ? "#FFF" : "#666"} size={isMobile ? 10 : 11}>Ваш город</Text>
                        <Text bold size={isMobile ? 13 : 15} color={isMobile ? "#FFF" : "#222"}>{city.name}</Text>
                    </div>

                </div>
            )
        }
    }

    /* Draw Search part */
    _search = () => {

        const { city } = this.props
        const { search } = this.state

        if (city && city.name) {
            return (
                <div className="header-search">
                    <input value={search} onChange={event => this.search(event.target.value)}
                           placeholder={`Искать заведений в ${city.name}`}/>
                    <div onClick={() => this.goSearch()} className="header-search-button">
                        <img src="/images/search.png" alt="Search"/>
                    </div>
                    {this._result()}
                </div>
            )
        }
    }


    /* Draw Top part */
    _top = () => {

        return (
            <div className="header-top">
            <div className="header-top-wrapper">

                    {this._address(true)}

                    {/* ADDRESS */}
                    {/* <div className="header-top-address">
                        <Text medium color="white" size={13}>Казахстан, г.Алматы, пр.Гагарина, 133/1</Text>
                    </div> */}

                    {/* LANGUAGE */}
                    <div className="header-top-language">
                        <img src="/images/language.png" alt="Language" />
                        <Text left={6} medium color="white" size={13}>Русский</Text>

                        <div className="header-top-language-list">

                            <div className="header-top-language-corner" />

                            <div className="header-top-language-item">
                                <span>KZ</span> Қазақша
                            </div>
                            <div className="header-top-language-item">
                                <span>TR</span> Türkçe
                            </div>
                            <div className="header-top-language-item">
                                <span>RU</span> Русский
                            </div>
                            <div className="header-top-language-item">
                                <span>EN</span> English
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        )
    }


    render = () => {

        const { cart, token } = this.props
        const user = (token && !isExpired(token)) ? decodeToken(token) : null

        return (
            <div className="header">

                {this._top()}

                <div className="header-box">
                    <div className="header-wrapper">

                        <div className="header-left-box">

                            {/* HEADER LOGO */}
                            <div className="header-logo">
                                <Link to="/">
                                    <img src="/images/logo.png" alt="E-QR" />
                                    <Text color="#666" size={14} top={5}>Электронное QR меню</Text>
                                </Link>
                            </div>

                            {/* HEADER ADDRESS */}
                            {this._address()}

                            {/* HEADER SEARCH */}
                            {this._search()}

                        </div>


                        <div className="header-right-box">

                            {/* HEADER CONTACT INFORMATION */}
                            <div className="header-contact">
                                <img src="/images/phone.png" alt="Phone" />
                                <div className="header-contact-text">
                                    <Text color="#B4B8B3" size={12}>24/7 Техподдержка</Text>
                                    <Text top={2} bold size={14}>+7 (747) 599-40-41</Text>
                                </div>
                            </div>


                            <div className="header-divider" />


                            {/* HEADER FAVOUTRITE */}
                            <div className="header-favourite" onClick={() => this.goFavourite()}>
                                <img src="/images/heart.png" alt="Heart" />
                            </div>


                            <div className="header-divider" />

                            <div onClick={() => this.goCart()} className="header-cart">
                                <img src="/images/cart.png" alt="Cart" />
                                {cart.length > 0 && <span>{cart.length}</span>}
                                {cart.length === 0 &&
                                    <div className="header-empty-cart">
                                        <div className="header-cart-corner" />
                                        <img src="/images/empty-cart.png" alt="Empty Cart" />
                                        <div className="header-empty-cart-box">
                                            <h3>Корзина пуста</h3>
                                            <p>Добавьте что-то из категории для оформления заказа</p>
                                        </div>
                                    </div>
                                }
                            </div>


                            <div className="header-divider" />

                            {/* HEADER USER */}
                            <div className="header-user" onClick={() => this.goUser()}>

                                {(user && user.image) ? <img src={`${env.mediapoint}users/${user.image}`} alt="user" className="ava" /> : <img src="/images/user.png" alt="user" />}

                                {user
                                    ? <div className="header-user-text">
                                        <Text color="#B4B8B3" size={12}>Привет</Text>
                                        <Text top={2} bold size={14}>{user.name}</Text>
                                    </div>
                                    : <div className="header-user-text">
                                        <Text color="#B4B8B3" size={12}>Мой аккаунт</Text>
                                        <Text top={2} bold size={14}>Войти</Text>
                                    </div>
                                }

                            </div>

                        </div>

                    </div>
                </div>

            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        cart: state.cart,
        city: state.city,
        token: state.token,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggle: data => dispatch({ type: 'TOGGLE_AUTH', payload: data }),
        toggleCity: data => dispatch({ type: 'TOGGLE_CITY', payload: data }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header))