import React, { Component } from "react"

/* Components */
import { Block } from '../components'

/* React Router */
import { Link } from "react-router-dom"



/* Page 404 Not Found */
class NotFound extends Component {

    componentDidMount = () => {
        window.scroll(0, 0)
    }

    render = () => {
        return (
            <Block>

                <div className="not-found-page">
                    <img src="/images/404.png" alt="404" />
                    <h3>Ошибка 404</h3>
                    <p>Такая страница не существует либо ещё не создана</p>
                    <Link to="/" className="not-found-button">На главную</Link>
                </div>

            </Block>
        )
    }

}

export default NotFound