import React, { Component } from "react"

/* Components */
import { Block, Loading, Network } from '../components'

/* Widgets */
import { Slide, Category, Restaurants } from '../widgets/Home'

/* REST API */
import { get } from '../api/Home'

/* Redux */
import { connect } from 'react-redux'

/* Helpers */
import { withRouter } from '../helpers'


/* Page Home */
class Home extends Component {

    constructor() {
        super()

        this.state = {
            data: null,
            loading: true,
            error: false,
            network: false
        }
    }


    componentDidMount = () => {
        this.load()
    }

    /* Load Data */
    load = () => {
        const { city } = this.props
        const data = {
            cityID: city.id
        }

        get(data).then(response => {
            if (response.status === 200) {
                this.setState({ data: response.data, network: false, error: false })
            }
            else {
                this.setState({ error: true })
            }
        }).catch(() => {
            this.setState({ network: true })
        }).finally(() => {
            this.setState({ loading: false })
        })
    }


    render = () => {

        const { loading, network, error, data } = this.state

        if (loading) {
            return (
                <Block>
                    <Loading />
                </Block>
            )
        }

        if (network) {
            return (
                <Block>
                    <Network reload={() => this.load()} />
                </Block>
            )
        }

        if (error || !data) {
            return (
                <Block>
                    <Network error reload={() => this.load()} />
                </Block>
            )
        }

        return (
            <Block>

                <Slide data={data.slides} />
                <Category data={data.categories} />
                <Restaurants title="Топ заведений" data={data.top} />
                <Restaurants title="Новые заведений" data={data.fresh} />
                <Restaurants title="Бесплатная доставка" data={data.free} />

            </Block>
        )
    }

}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        token: state.token,
        city: state.city
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home))