import React, { Component } from "react"

/* Redux */
import { connect } from 'react-redux'

/* Widgets */
import { Menu } from '../../widgets'

/* Helpers */
import { withRouter } from '../../helpers'

/* JWT */
import { isExpired, decodeToken } from "react-jwt"

/* Constants */
import { env } from "../../constants"

/* Router */
import { Link } from 'react-router-dom'

/* Switch */
import Switch from "react-switch"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Swal */
import swal from 'sweetalert'

/* REST API */
import { get, change } from '../../api/User'




/* Page Account */
class Account extends Component {


    constructor() {
        super()

        this.state = {
            loading: true,
            isSubscribe: false
        }
    }


    componentDidMount = () => {

        const { token, navigate } = this.props

        if (!token || isExpired(token)) {
            navigate('/')
        }
        else {
            this.load()
        }

    }


    /* Load User Data */
    load = () => {

        const { token } = this.props

        get(token).then(response => {
            if (response.status === 200) {
                if (parseInt(response.data.isSubscribe) === 1) {
                    this.setState({ isSubscribe: true })
                }
            }
        }).finally(() => {
            this.setState({ loading: false })
        })

    }


    /* Change value */
    change = value => {

        const { token } = this.props

        this.setState({ isSubscribe: value })

        change(token, { value }).then(response => {
            if (response.status === 200) {
                const text = value ? "Вы подписались на рассылку" : "Вы отписались от рассылки"
                swal({
                    title: text,
                    text: ' ',
                    icon: 'success',
                    buttons: false,
                    timer: 1500
                })
            }
        })
    }


    logout = () => {

        const { navigate, removeToken } = this.props

        swal({
            title: 'Выйти из аккаунта?',
            text: 'Вы дейсвительно хотите выйти?',
            icon: 'warning',
            buttons: ["Отменить", "Да, выйти!"],
            dangerMode: true,

        }).then(confirmed => {
            if (confirmed) {
                removeToken()
                navigate("/")
            }
        })
    }


    /* Draw Account Data */
    _data = () => {

        const { token } = this.props
        const user = token ? decodeToken(token) : null

        if (user) {
            return (
                <div className="account-data">
                    {user.image
                        ? <img className="ava" src={`${env.mediapoint}users/${user.image}`} alt="Avatar" />
                        : <img src="/images/mobile/user.png" alt="Avatar" />
                    }
                    <p>{user.name}</p>
                    <span>{user.email}</span>
                </div>
            )
        }
    }


    render = () => {

        const { isSubscribe, loading } = this.state
        const { navigate } = this.props

        return (
            <div className="account">

                <div className="account-header">
                    <h2>Профиль</h2>
                    <div onClick={() => navigate("/account/profile")} className="account-header-settings">
                        <img src="/images/mobile/settings.png" alt="Settings" />
                    </div>
                </div>

                {this._data()}

                <div className="account-menu">

                    <Link to="/account/orders" className="account-menu-item">
                        <div className="account-menu-item-left">
                            <img src="/images/mobile/order.png" alt="Order" />
                            <p>Мои заказы</p>
                        </div>
                        <div className="account-menu-item-right">
                            <img src="/images/mobile/right.png" alt="Right" />
                        </div>
                    </Link>

                    <Link to="/account/address" className="account-menu-item">
                        <div className="account-menu-item-left">
                            <img src="/images/mobile/address.png" alt="Address" />
                            <p>Мои адреса</p>
                        </div>
                        <div className="account-menu-item-right">
                            <img src="/images/mobile/right.png" alt="Right" />
                        </div>
                    </Link>

                    <Link to="/account/password" className="account-menu-item">
                        <div className="account-menu-item-left">
                            <img src="/images/mobile/lock.png" alt="Lock" />
                            <p>Изменить пароль</p>
                        </div>
                        <div className="account-menu-item-right">
                            <img src="/images/mobile/right.png" alt="Right" />
                        </div>
                    </Link>

                    <Link to="/account/contact" className="account-menu-item">
                        <div className="account-menu-item-left">
                            <img src="/images/mobile/chat.png" alt="Chat" />
                            <p>Контакты</p>
                        </div>
                        <div className="account-menu-item-right">
                            <img src="/images/mobile/right.png" alt="Right" />
                        </div>
                    </Link>


                    <div className="account-ad-box">

                        <div className="account-ad-box-text">
                            <b>Реклама и акции</b>
                            <p>Подписаться на рассылку</p>
                        </div>

                        {loading
                            ? <LoadingOutlined />
                            : <Switch
                                onChange={value => this.change(value)}
                                checked={isSubscribe}
                                onColor="#70b715"
                                onHandleColor="#74C70A"
                                offHandleColor="#fff"
                                offColor="#d7d7d7"
                                handleDiameter={25}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                height={20}
                                width={40}
                            />
                        }

                    </div>

                    <div onClick={() => this.logout()} className="account-logout">Выйти из профиля</div>

                </div>

                <Menu />
            </div>
        )
    }

}


const mapStateToProps = state => {
    return {
        token: state.token
    }
}

const mapDispatchToProps = dispatch => {
    return {
        removeToken: () => dispatch({ type: 'REMOVE_TOKEN', payload: null })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Account))