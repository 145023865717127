import React, { Component } from "react"

/* Components */
import { Block, Loading, Network } from '../components'

/* Pages */
import NotFound from "./NotFound"

/* Helpers */
import { withRouter } from '../helpers'

/* REST API */
import { get, search as searchProducts } from '../api/Restaurant'

/* Constants */
import { env } from "../constants"

/* Widgets */
import { Header, Search } from '../widgets/Restaurant'
import ProductCategories from "../widgets/ProductCategory/ProductCategories"
import Products from "../widgets/Products/Products"
import { Footer, Menu } from "../widgets"
import { Galleries } from "../widgets/Gallery"
import {LoadingOutlined} from "@ant-design/icons";

/* Page Restaurant */
class Restaurant extends Component {

    constructor() {
        super()

        this.state = {

            restaurant: null,
            productCategories: [],
            galleries: [],

            categoryIndex: 0,

            loading: false,
            error: false,
            network: false,
            notfound: false,

            search: "",
            searchResult: [],
            searchLoading: false
        }

        this._timing = null
    }


    componentDidMount = () => {
        this.load()
        window.scrollTo(0, 0)
    }


    /* Load Data */
    load = () => {

        const { slug } = this.props.params

        this.setState({ loading: true })

        if (slug) {
            get(slug).then(response => {
                if (response.status === 200) {
                    this.setState({ restaurant: response.data.restaurant, productCategories: response.data.productCategories, galleries: response.data.galleries, error: false, network: false })
                }
                else if (response.status === 404) {
                    this.setState({ notfound: true })
                }
                else {
                    this.setState({ error: true })
                }
            }).catch(() => {
                this.setState({ network: true })
            }).finally(() => {
                this.setState({ loading: false })
            })
        }
    }


    setSearch = search => {

        this.setState({ search })

        const { slug } = this.props.params

        if (this._timing) {
            clearTimeout(this._timing)
            this._timing = null
        }

        this.setState({ searchLoading: true })

        this._timing = setTimeout(() => {
            searchProducts(slug, { text: search }).then(response => {
                if(response.status === 200) {
                    this.setState({ searchResult: response.data })
                }
            }).finally(() => {
                clearTimeout(this._timing)
                this._timing = null
                this.setState({ searchLoading: false })
            })
        }, 500)

    }


    /* Determine products */
    products = () => {

        const { search, productCategories, searchResult } = this.state

        if (search === "") {
            return productCategories
        }

        return searchResult
    }


    render = () => {

        const { loading, notfound, network, error, search, restaurant, searchLoading, categoryIndex, galleries } = this.state

        if (loading) {
            return <Loading />
        }

        if (notfound) {
            return <NotFound />
        }

        if (network) {
            return (
                <Block>
                    <Network reload={() => this.load()} />
                </Block>
            )
        }

        if (error || !restaurant) {
            return (
                <Block>
                    <Network error reload={() => this.load()} />
                </Block>
            )
        }


        const products = this.products()

        return (
            <div className="restaurant-page">

                <div className="restaurant-banner">

                    {restaurant.image ? <img className="restaurant-banner-image"
                                             src={`${env.mediapoint}restaurants/${restaurant.image}`}
                                             alt="Banner"/> : null}

                    <div className="restaurant-layout">

                        <Header search={search} setSearch={search => this.setSearch(search)} data={restaurant}/>

                        <div className="restaurant-data">

                            <div className="restaurant-data-head">
                                <div className="restaurant-data-text">
                                    {restaurant.name ? <h1>{restaurant.name}</h1> : null}
                                    {restaurant.tagline ? <p>{restaurant.tagline}</p> : null}
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="mobile-restaurant">
                    {/*<div className="mobile-restaurant-data">*/}
                    {/*    <div className="mobile-restaurant-data-left">*/}
                    {/*        {restaurant.name ? <h3>{restaurant.name}</h3> : null}*/}
                    {/*        {restaurant.tagline ? <p>{restaurant.tagline}</p> : null}*/}
                    {/*    </div>*/}

                    {/*    <div className="mobile-restaurant-data-right">*/}
                    {/*        <p>График работы <br></br> c {restaurant.workTimeStart ? restaurant.workTimeStart : '09:00'} до {restaurant.workTimeEnd ? restaurant.workTimeEnd : '18:00'}</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {restaurant.address ?
                        <div className="mobile-restaurant-address">
                            <div className="mobile-restaurant-address-left">
                                <img src="/images/mobile/location.svg" alt="Location"/>
                                <p>Адрес: {restaurant.address}</p>
                            </div>

                            <div className="mobile-restaurant-address-right">
                                <p>Проложить маршрут</p>
                            </div>
                        </div>
                        : null
                    }

                    <Search search={search} setSearch={search => this.setSearch(search)} data={restaurant}/>
                </div>

                {/* Draw Search Loading */}
                {searchLoading &&
                    <div className="restaurant-products-loading">
                        <LoadingOutlined/>
                    </div>
                }
                {search !== "" && !searchLoading && products.length === 0 ?
                    <div className="restaurant-search-answer">По вашему запросу "{search}" ничего не
                        найдено!</div> : null}

                <div>

                    {/* Draw Categories */}
                    <ProductCategories index={categoryIndex} setIndex={index => this.setState({categoryIndex: index})}
                                       productCategories={products}/>

                    {/* Draw Products */}
                    {products.map((item, index) => <Products restaurant={restaurant} key={`${index}`}
                                                             products={item.Products} categoryName={item.name}
                                                             onCategoryChange={(index) => this.setState({categoryIndex: index})}/>)}

                    {/* Draw Galleries */}
                    <Galleries data={galleries}/>

                {/* Draw Footer */}
                <Footer/>

                </div>

                {/* Draw Mobile Menu */}
                <Menu/>
            </div>

        )
    }

}

export default withRouter(Restaurant)