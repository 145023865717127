import React, { Component } from "react"

/* Components */
import { Block } from "../components"

/* Widgets */
import { Basket } from "../widgets/Cart"



/* Page Cart */
class Cart extends Component {

    render = () => {
        return (
            <Block>
                <Basket />
            </Block>
        )
    }
}

export default Cart