import axios from "axios"
import { env } from '../constants'

/* LIST */
export const get = slug => {
    const uri = `${env.startpoint}restaurant/get/${slug}`
    return axios.get(uri).then(response => response).catch(error => error.response)
}

/* SEARCH */
export const search = (slug, data) => {
    const uri = `${env.startpoint}restaurant/search/${slug}`
    return axios.post(uri, data).then(response => response).catch(error => error.response)
}