import React, {Component} from "react"

/* Components */
import {Loading, Network} from '../../components'

/* Helpers */
import {withRouter, utils} from '../../helpers'

/* Redux */
import {connect} from 'react-redux'

/* JWT */
import {isExpired} from "react-jwt"

/* Widgets */
import {OrderDetail} from '../../widgets/Order'

/* REST API */
import {create as createAddress} from '../../api/Address'
import {create, user} from '../../api/Order'

/* Mask */
import InputMask from 'react-input-mask'

/* Modal */
import Modal from 'react-modal'

/* Swal */
import Swal from 'sweetalert2'
import address from "../Address";

/* PAYMENT METHODS */
const CASH = "cash"

const PAYMENT_METHODS = [
    {key: CASH, text: "Наличными", disabled: false}
]


/* Widget Checkout */
class Order extends Component {

    constructor() {
        super()

        this.state = {
            name: '',
            phone: '',

            restaurants: [],

            addresses: [],

            loading: true,
            network: false,
            error: false,

            deliveryType: 'delivery',
            comment: '',

            paymentMethod: CASH,

            addressID: 0,

            modal: false,

            addressName: '',
            address: '',
            flat: '',
            floor: '',
            entrance: '',
            width: window.innerWidth
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }


    componentDidMount = () => {
        this.load()
        this.updateWindowDimensions()
        window.addEventListener('resize', this.updateWindowDimensions)
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions)
    }

    updateWindowDimensions = () => {
        this.setState({width: window.innerWidth});
    }

    /* Load */
    load = () => {

        const {token, navigate, cart} = this.props

        if (cart.length === 0) {
            navigate('/')
            return
        }

        if (!token || isExpired(token)) {
            navigate('/')
            return
        } else {
            user(token).then(response => {
                if (response.status === 200) {

                    const data = response.data

                    const validate = value => (value === undefined || value === null || value === "") ? "" : value

                    this.setState({
                        name: validate(data.name),
                        phone: validate(data.phone),
                        error: false,
                        network: false
                    })

                    if (data.address && Array.isArray(data.address) && data.address.length > 0) {
                        this.setState({addresses: data.address})
                        this.initRestaurants('delivery', data.address[0])
                    }

                } else {
                    this.setState({error: true})
                }
            }).catch(() => {
                this.setState({error: true})
            }).finally(() => {
                this.setState({loading: false})
            })

        }

    }

    initRestaurants = (deliveryType = "delivery", address = null) => {

        const {cart} = this.props

        let restaurants = []

        if (cart && Array.isArray(cart) && cart.length > 0) {
            cart.forEach(restaurant => {
                restaurants.push({
                    ...restaurant,
                    deliveryType,
                    address
                })
            })
        }

        this.setState({restaurants})
    }

    /* Set Delivery Type */
    setDelivery = (id, deliveryType) => {

        const validate = value => (value === undefined || value === null)

        if (validate(id) || validate(deliveryType)) {
            return
        }

        const {restaurants} = this.state
        let data = restaurants
        const index = data.findIndex(e => parseInt(e.id) === parseInt(id))

        if (index > -1) {
            data[index].deliveryType = deliveryType
            this.setState({restaurants: data})
        }
    }


    /* Set Address */
    setAddress = (id, address) => {

        const validate = value => (value === undefined || value === null)

        if (validate(id) || validate(address)) {
            return
        }

        const {restaurants} = this.state
        let data = restaurants
        const index = data.findIndex(e => parseInt(e.id) === parseInt(id))

        if (index > -1) {
            data[index].address = address
            this.setState({restaurants: data})
        }
    }

    /* Draw products */
    _products = () => {

        const {addresses, restaurants} = this.state

        if (restaurants.length > 0) {
            return (
                <div className="order-restaurants">
                    {restaurants.map((restaurant, index) =>
                        <OrderDetail
                            key={`${index}`}
                            data={restaurant}
                            addresses={addresses}
                            openAddressModal={() => this.setState({modal: true})}
                            setDelivery={(id, type) => this.setDelivery(id, type)}
                            setAddress={(id, address) => this.setAddress(id, address)}
                        />
                    )}
                </div>
            )
        }

    }

    /* CREATE ADDRESS */
    createAddress = () => {

        const {addressName, address, flat, floor, entrance, addresses} = this.state
        const {token, city} = this.props

        if (addressName === '') {
            Swal.fire({
                icon: 'error',
                title: 'Ошибка!',
                text: 'Введите название адреса',
                showConfirmButton: false,
                timer: 1500
            })
            return
        }

        if (address === '') {
            Swal.fire({icon: 'error', title: 'Ошибка!', text: 'Введите адрес', showConfirmButton: false, timer: 1500})
            return
        }

        const data = {cityID: city.id, name: addressName, address, flat, floor, entrance}


        createAddress(token, data).then(response => {

            if (response.status === 200) {

                if (addresses.length === 0) {
                    this.setState({addressID: response.data.id})
                }

                addresses.push(response.data)

                this.setState({
                    addresses,
                    addressName: '',
                    address: '',
                    flat: '',
                    floor: '',
                    entrance: '',
                    modal: false
                })
                Swal.fire({icon: 'success', title: 'Адрес успешно создан', showConfirmButton: false, timer: 1500})
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ошибка!',
                    text: 'Что-по пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Ошибка!',
                text: 'Что-по пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })

    }

    order = () => {

        const {name, phone, paymentMethod, width, restaurants} = this.state
        const {clear, token, navigate} = this.props

        if (name === '') {
            Swal.fire({
                icon: 'error',
                title: 'Ошибка!',
                text: 'Введите свое имя',
                showConfirmButton: false,
                timer: 1500
            })
            return
        }

        if (phone === '') {
            Swal.fire({icon: 'error', title: 'Ошибка!', text: 'Введите телефон', showConfirmButton: false, timer: 1500})
            return
        }

        let dataRestaurants = []
        restaurants.forEach(item => {
            let total = 0
            let products = []
            item.products.forEach(product => {
                const price = parseInt(product.price)
                total += price * parseInt(product.amount)
                products.push({id: product.id, quantity: product.amount})
            })
            dataRestaurants.push({
                products: products,
                addressID: item.address.id,
                deliveryType: item.deliveryType,
                id: item.id,
                name: item.name,
                total: total
            })
        })

        const data = {
            name,
            phone,
            paymentMethod,
            restaurants: dataRestaurants
        }

        create(token, data).then(response => {
            if (response.status === 200) {
                clear()
                navigate(width > 768 ? `/my-orders` : `/account/orders`)
                Swal.fire({icon: 'success', title: 'Заказ принят', showConfirmButton: false, timer: 1500})
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ошибка!',
                    text: 'Что-по пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }

        }).catch((exc) => {
            Swal.fire({
                icon: 'error',
                title: 'Ошибка!',
                text: 'Что-по пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })
    }

    /* Draw Address Modal */
    _address = () => {

        const {modal, addressName, address, flat, floor, entrance} = this.state
        const {city} = this.props

        return (
            <Modal
                isOpen={modal}
                onRequestClose={() => this.setState({modal: false})}
                className="address-modal"
                overlayClassName="address-overlay"
            >

                <div onClick={() => this.setState({modal: false})} className="address-modal-close">
                    <img src="/images/x.png" alt="X"/>
                </div>

                <div className="address-modal-form">
                    <h2>Добавить адрес</h2>

                    <p>Город</p>
                    <select value={city.id} disabled>
                        <option value={city.id}>{city.name}</option>
                    </select>

                    <p>Название</p>
                    <input type="text" value={addressName}
                           onChange={event => this.setState({addressName: event.target.value})}
                           placeholder="Введите название (Дом, Работа и т.д.)"/>


                    <p>Улица, дом</p>
                    <input type="text" value={address} onChange={event => this.setState({address: event.target.value})}
                           placeholder="Введите улицу и дом"/>

                    <div className="address-modal-row">

                        <div className="address-modal-form-box">
                            <p>Квартира</p>
                            <input type="text" value={flat}
                                   onChange={event => this.setState({flat: event.target.value})}
                                   placeholder="Номер кв."/>
                        </div>

                        <div className="address-modal-form-box">
                            <p>Этаж</p>
                            <input type="text" value={floor}
                                   onChange={event => this.setState({floor: event.target.value})} placeholder="Этаж"/>
                        </div>

                        <div className="address-modal-form-box">
                            <p>Подъезд</p>
                            <input type="text" value={entrance}
                                   onChange={event => this.setState({entrance: event.target.value})}
                                   placeholder="Подъезд"/>
                        </div>

                    </div>

                    <div onClick={() => this.createAddress()} className="address-modal-button">
                        Сохранить
                    </div>
                </div>

            </Modal>
        )
    }

    render = () => {

        const {
            loading,
            network,
            error,
            name,
            phone,
            paymentMethod,
        } = this.state
        const {navigate} = this.props

        if (loading) {
            return <Loading/>
        }

        if (error) {
            return <Network error reload={() => this.load()}/>
        }

        if (network) {
            return <Network reload={() => this.load()}/>
        }


        return (
            <div className="order">
                <div className="order-wrapper">

                    <div className="order-title">
                        <div onClick={() => navigate(-1)} className="order-back">
                            <img src="/images/back.png" alt="Back"/>
                        </div>
                        <h2>Оформление заказа</h2>
                    </div>

                    <div className="order-box">

                        <div className="order-form">

                            <div className="order-form-box">
                                <h3>Личные данные</h3>

                                <div className="order-form-row">
                                    <p>Имя</p>
                                    <input type="text" value={name}
                                           onChange={event => this.setState({name: event.target.value})}
                                           placeholder="Введите имя"/>
                                </div>

                                <div className="order-form-row">
                                    <p>Номер телефона</p>
                                    <InputMask value={phone}
                                               onChange={event => this.setState({phone: event.target.value})}
                                               mask="+7 (999) 999-99-99" placeholder="Введите номер телефона"/>
                                </div>
                            </div>

                            {this._products()}

                            <div className="order-form-box">
                                <h3>Способы оплаты</h3>
                                <div className="order-payment-methods">
                                    {PAYMENT_METHODS.map((item, index) =>
                                        <div
                                            className={paymentMethod === item.key ? `payment-method` : `payment-method-disabled`}
                                            key={`${index}`}>
                                            {paymentMethod === item.key ? <div className="checked">
                                                <div className="checked-inner"/>
                                            </div> : <div className="unchecked"/>}
                                            <span>{item.text}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div onClick={() => this.order()} className="order-box-button">Потвердить и оформить заказ</div>

                    </div>

                </div>

                {this._address()}


                <div className="order-box-mobile-bottom">
                    <div onClick={() => this.order()} className="order-box-mobile-button">Потвердить и оформить заказ
                    </div>
                </div>

            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        cart: state.cart,
        token: state.token,
        city: state.city
    }
}


const mapDispatchToProps = dispatch => {
    return {
        clear: () => dispatch({type: 'CLEAR_CART', payload: []})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Order))