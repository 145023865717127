import React, { Component } from "react"

/* Helpers */
import { utils } from '../../helpers'

/* Constants */
import { env } from '../../constants'

/* React Router */
import { Link } from "react-router-dom"

/* Constants */
const WRAPPER = 1200


/* Widget Restaurant */
class Restaurant extends Component {

    constructor() {
        super()

        this.state = {
            width: 0
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }


    componentDidMount = () => {
        this.updateWindowDimensions()
        window.addEventListener('resize', this.updateWindowDimensions)
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions)
    }

    /* Window Resize Handler */
    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth })
    }


    render = () => {

        const { data } = this.props
        const { width } = this.state

        if (data === null || data === undefined) {
            return <div />
        }

        let size = (WRAPPER - 40) / 3

        if (width < WRAPPER + 40) {
            size = (width - 81) / 3
        }

        if (width < 1000) {
            size = (width - 61) / 2
        }

        if (width < 600) {
            size = width - 40
        }

        return (
            <Link to={`/restaurant/${data.slug}`} className="restaurant" style={{ width: size, height: size / 1.5 }}>

                {data.image ? <img src={`${env.mediapoint}restaurants/${data.image}`} alt={data.title} /> : null }

                <div className="restaurant-shadow-content">
                    <p>{data.name}</p>
                    <span>{data.tagline}</span>

                    <div className="restaurant-bottom">

                        <div className="restaurant-bottom-left">

                            <div className="restaurant-bottom-item">
                                <img src="/images/bike.png" alt="Bike" />
                                <div className="restaurant-bottom-text">от {utils.convertor(data.delivery)}</div>
                            </div>

                            <div className="restaurant-bottom-item restaurant-bottom-space">
                                <img src="/images/star.png" alt="Star" />
                                <div className="restaurant-bottom-text">{data.rating ? data.rating : "-"}</div>
                            </div>

                        </div>

                        <div className="restaurant-bottom-item">
                            <img src="/images/location-white.png" alt="Location" />
                            <div className="restaurant-bottom-text">{data.distance ? data.distance : "-"}</div>
                        </div>

                    </div>

                </div>

            </Link>
        )
    }

}

export default Restaurant