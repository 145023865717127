import React, { Component } from "react"

/* Modal */
import Modal from 'react-modal'

/* Connect */
import { connect } from 'react-redux'

/* Helpers */
import { withRouter } from '../helpers'

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'



/* Widget City */
class City extends Component {

    constructor() {
        super()

        this.state = {
            search: ''
        }
    }


    /* Change City action */
    changeCity = item => {

        const { toggle, setCity, clear } = this.props

        setCity(item)
        toggle(false)
        clear()

        window.location.reload()
    }


    /* Find action */
    find = () => {

        const { search } = this.state
        const { cities } = this.props

        if (search === '' || search.length === 0) {
            return cities
        }

        const text = search.toLowerCase()

        let result = []

        cities.forEach(item => {
            const name = item.name.toLowerCase()
            const slug = item.slug.toLowerCase()

            if (name.indexOf(text) > -1 || slug.indexOf(text) > -1) {
                result.push(item)
            }
        })

        return result
    }


    render = () => {

        const { visible, toggle, cities, city } = this.props
        const { search } = this.state

        const data = this.find()

        return (
            <Modal
                isOpen={visible}
                onRequestClose={() => toggle(false)}
                className="city-modal"
                overlayClassName="city-overlay"
                closeTimeoutMS={200}
            >

                <div onClick={() => toggle(false)} className="city-modal-close">
                    <img src="/images/x.png" alt="X" />
                </div>

                <div className="city-modal-header">
                    <h2>Выберите город</h2>

                    <div className="city-modal-search">
                        <img src="/images/search-black.png" alt="Search" />
                        <input
                            placeholder="Поиск города"
                            value={search}
                            onChange={event => this.setState({ search: event.target.value })}
                        />
                    </div>

                </div>

                {cities.length === 0 &&
                    <div className="city-modal-loading">
                        <LoadingOutlined />
                    </div>
                }

                {cities.length > 0 && data.length === 0 &&
                    <div className="city-modal-loading">
                        <p>По вашему запросу ничего не найдено</p>
                    </div>
                }

                {data.length > 0 &&
                    <div className="city-modal-box">
                        {data.map((item, index) =>
                            <div onClick={() => this.changeCity(item)} className={parseInt(city.id) === parseInt(item.id) ? "city-modal-item city-modal-item-active" : "city-modal-item"} key={`${index}`}>{item.name}</div>
                        )}
                    </div>
                }

            </Modal>
        )
    }

}

const mapStateToProps = state => {
    return {
        visible: state.cityModal,
        cities: state.cities,
        city: state.city
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggle: data => dispatch({ type: 'TOGGLE_CITY', payload: data }),
        setCity: data => dispatch({ type: 'SET_CITY', payload: data }),
        clear: () => dispatch({type: 'CLEAR_CART', payload: []})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(City))