import React, { Component } from "react"

/* Components */
import ProductCategory from "./ProductCategory"


/* Widget Product Categories */
class ProductCategories extends Component {

    render = () => {

        const { productCategories, index, setIndex } = this.props

        /* Empty */
        if (productCategories.length === 0) {
            return <div />
        }

        return (
            <div className="product-categories-container">
                <div className="product-categories">
                    {productCategories.map((item, i) => <ProductCategory key={`${i}`} data={item} isActive={index === i} onClick={() => setIndex(i)} />)}
                </div>
            </div>

        )
    }

}


export default ProductCategories