import React, { Component } from "react"

/* React Router */
import { Link } from "react-router-dom"

/* Redux */
import { connect } from 'react-redux'

/* JWT */
import { isExpired, decodeToken } from "react-jwt"

/* Helpers */
import { withRouter } from '../../helpers'

/* Constants */
import { env } from '../../constants'

/* API */
import { get } from "../../api/Restaurant";

/* Widget Restaurant Header */
class Header extends Component {

    constructor(props) {
        super(props)

        const { text } = props
        let search = ''

        if (text) {
            search = text
        }

        this.state = {
            search,
            loading: false
        }

        this._timeout = 0
    }

    componentDidMount = () => {
        window.addEventListener("scroll", this.sticky)
    }

    componentWillUnmount = () => {
        window.removeEventListener("scroll", this.sticky)
    }


    /* Scroll Event Listener */
    sticky = () => {
        const header = document.querySelector('.restaurant-header')
        const logo = document.querySelector('.restaurant-logo')
        const scrollTop = window.scrollY
        scrollTop >= 40 ? header.classList.add('restaurant-is-sticky') : header.classList.remove('restaurant-is-sticky')
        scrollTop >= 40 ? logo.src = '/images/logo.png' : logo.src = '/images/logo-white.png'
    }

    /* Go To Cart */
    goCart = () => {
        const { cart, navigate } = this.props

        if (cart.length > 0) {
            navigate("/cart")
        }
    }

    /* Go To User */
    goUser = () => {
        const { token, toggle, navigate } = this.props

        if (!token || isExpired(token)) {
            toggle(true)
        }
        else {
            navigate('/profile')
        }

    }

    /* Go To Favourite */
    goFavourite = () => {
        const { token, toggle, navigate } = this.props

        if (!token || isExpired(token)) {
            toggle(true)
        }
        else {
            navigate('/favourite')
        }
    }

    /* Go To Search */
    goSearch = () => {
        const { navigate, data } = this.props
        const { search } = this.state

        if (search !== "") {
            // Use template literals to construct the URL with the search query
            // <Link to={`/restaurant/${data.slug}`} className="restaurant" style={{ width: size, height: size / 1.5 }}></Link>
            navigate(`/restaurant/${data.slug}?search=${encodeURIComponent(search)}`)
        }
    }

    search = text => {
        this.setState({ search: text });

        if (text !== "") {
            this.setState({ loading: true });

            if (this._timeout) {
                clearTimeout(this._timeout);
            }

            this._timeout = setTimeout(() => {
                const { data } = this.props;

                get(data.slug, text).then(response => {
                    if (response.status === 200) {
                        this.setState({ result: response.data });
                    }
                }).finally(() => {
                    this.setState({ loading: false });
                });
            }, 1000);
        }
    }



    render = () => {
        const { token, data, search, setSearch } = this.props
        const user = (token && !isExpired(token)) ? decodeToken(token) : null

        return (
            <div className="restaurant-header">
                <div className="restaurant-header-wrapper">
                    <div className="restaurant-header-box">

                        {/* HEADER LOGO */}
                        <div className="restaurant-header-logo">
                            <Link to="/">
                                <img className="restaurant-logo" src="/images/logo-white.png" alt="E-QR" />
                                <span>Электронное QR меню</span>
                            </Link>
                        </div>

                        {/* HEADER SEARCH */}
                        <div className="restaurant-header-search">
                            <input value={search} onChange={event => setSearch(event.target.value)}
                                placeholder={`Искать в ${data.name}`} />
                            <div onClick={() => this.goSearch()} className="restaurant-header-search-button">
                                <img src="/images/search.png" alt="Search" />
                            </div>
                        </div>

                        {/* HEADER USER */}
                        <div className="restaurant-header-user" onClick={() => this.goUser()}>
                            {(user && user.image) ?
                                <img src={`${env.mediapoint}users/${user.image}`} alt="user" className="ava" /> :
                                <img src="/images/user.png" alt="user" />}

                            {user
                                ? <div className="restaurant-header-user-text">
                                    <span>Привет</span>
                                    <p>{user.name}</p>
                                </div>
                                : <div className="restaurant-header-user-text">
                                    <span>Мой аккаунт</span>
                                    <p>Войти</p>
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </div>
        )
    }

}


const mapStateToProps = state => {
    return {
        auth: state.auth,
        cart: state.cart,
        city: state.city,
        token: state.token
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggle: data => dispatch({ type: 'TOGGLE_AUTH', payload: data }),
        toggleCity: data => dispatch({ type: 'TOGGLE_CITY', payload: data }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header))