/* REST API */
import { list as favourites } from '../api/Favourite'
import { list as cities } from '../api/City'

/* JWT */
import { isExpired } from "react-jwt"


export const actionFavourites = store => {

    const token = localStorage.getItem("token")

    if (token && !isExpired(token)) {
        favourites(token).then(response => {
            if (response.status === 200) {
                store.dispatch({
                    type: 'INIT_FAVOURITE',
                    payload: response.data
                })
            }
        })
    }
}


export const actionCity = store => {

    const city = localStorage.getItem("city")

    cities().then(response => {
        
        if (response.status === 200) {

            store.dispatch({
                type: 'LOAD_CITIES',
                payload: response.data
            })

            if (!city) {
                if(response.data.length > 0) {
                    store.dispatch({
                        type: 'SET_CITY',
                        payload: response.data[0]
                    })
                }
            }

        }
    })
}