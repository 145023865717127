import axios from "axios"
import { env } from '../constants'


/* LIST */
export const get = (data) => {
    const uri = `${env.startpoint}home/get`
    const header = { params: data }
    return axios.get(uri, header).then(response => response).catch(error => error.response)
}

/* Search */
export const search = (data) => {
    const uri = `${env.startpoint}home/search`
    const header = { params: data }
    return axios.get(uri, header).then(response => response).catch(error => error.response)
}