
/* Order status fields */
const NEW = 'new'
const PENDING = 'pending'
const ONTHEWAY = 'ontheway'
const COMPLETED = 'completed'
const CANCELED = 'canceled'

const PICKUP = 'pickup'
const DELIVERY = 'delivery'

/*const CASH = "cash"
const CARD = "card"

const PAID = "paid"
const NOTPAID = "notpaid"*/



/* PRICE CONVERTOR */
export const convertor = price => {

    /* Check the price for the void */
    if (price === null || price === undefined)
        return ""

    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "\xa0₸"
}

/* CHECK DATA */
export const check = value => {

    /* Check the value for the void */
    if (value === null || value === undefined)
        return ""

    return value
}



/* ORDER DELIVERY STATUS */
export const deliveryStatus = (delivery, status) => {

    if (status === NEW) {
        return <div className="order-tag green">Заказ создан</div>
    }

    if (status === PENDING) {
        return <div className="order-tag yellow">В обработке</div>
    }

    if (status === ONTHEWAY) {
        return <div className="order-tag whiteblue">В пути</div>
    }

    if (status === COMPLETED) {
        if (delivery === PICKUP) {
            return <div className="order-tag darkgreen">Завершен</div>
        }
        if (delivery === DELIVERY) {
            return <div className="order-tag blue">Доставлен</div>
        }
    }

    if (status === CANCELED) {
        return <div className="order-tag red">Отменен</div>
    }

}




/* ORDER DELIVERY STATUS */
export const deliveryStatusText = (delivery, status) => {
    if (status === NEW) {
        return "Заказ создан"
    }

    if (status === PENDING) {
        return "В обработке"
    }

    if (status === ONTHEWAY) {
        return "В пути"
    }

    if (status === COMPLETED) {
        if (delivery === PICKUP) {
            return "Завершен"
        }
        if (delivery === DELIVERY) {
            return "Доставлен"
        }
    }

    if (status === CANCELED) {
        return "Отменен"
    }
}




/* ORDER DELIVERY STATUS */
export const deliveryStatusView = (delivery, status) => {

    const isNew = () => {

        const active = status === NEW
        const disabled = status === PENDING || status === ONTHEWAY || status === COMPLETED || status === CANCELED

        let style = active ? 'order-view-delivery active' : disabled ? 'order-view-delivery disabled' : 'order-view-delivery'

        return (
            <div className="order-view-delivery-item">
                <div className={style}>
                    {active ? <img src="/images/order/new-white.png" alt="NEW" /> : <img src="/images/order/new.png" alt="NEW" />}
                </div>
            </div>
        )
    }

    const isPending = () => {

        const active = status === PENDING
        const disabled = status === ONTHEWAY || status === COMPLETED || status === CANCELED

        let style = active ? 'order-view-delivery active' : disabled ? 'order-view-delivery disabled' : 'order-view-delivery'

        return (
            <div className="order-view-delivery-item">
                <div className={style}>
                    {active ? <img src="/images/order/pending-white.png" alt="Pending" /> : <img src="/images/order/pending.png" alt="Pending" />}
                </div>
            </div>
        )

    }

    const isOnTheWay = () => {

        if (delivery === DELIVERY) {

            const active = status === ONTHEWAY
            const disabled = status === COMPLETED || status === CANCELED

            let style = active ? 'order-view-delivery active' : disabled ? 'order-view-delivery disabled' : 'order-view-delivery'

            return (
                <div className="order-view-delivery-item">
                    <div className={style}>
                        {active ? <img src="/images/order/bike-white.png" className="bike" alt="Bike" /> : <img src="/images/order/bike.png" className="bike" alt="Bike" />}
                    </div>
                </div>
            )

        }
    }


    const isCompleted = () => {

        const active = status === COMPLETED
        const disabled = status === CANCELED

        let style = active ? 'order-view-delivery active' : disabled ? 'order-view-delivery disabled' : 'order-view-delivery'

        return (
            <div className="order-view-delivery-item">
                <div className={style}>
                    {active ? <img src="/images/order/flag-white.png" alt="Flag" /> : <img src="/images/order/flag.png" alt="Flag" />}
                </div>
            </div>
        )
    }


    return (
        <div className="order-view-delivery-box">
            {isNew()}
            {isPending()}
            {isOnTheWay()}
            {isCompleted()}
            <div className="order-view-delivery-line" />
        </div>
    )
}