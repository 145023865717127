import React, { Component } from "react"

/* Components */
import { Loading, Network } from '../../components'

/* Constants */
import { env } from '../../constants'

/* Helpers */
import { withRouter, utils } from '../../helpers'

/* Redux */
import { connect } from 'react-redux'

/* Widgets */
import Menu from "./Menu"

/* Mask */
import InputMask from 'react-input-mask'

/* REST API */
import { upload, get, edit } from '../../api/User'

/* Swal */
import swal from 'sweetalert'

/* JWT */
import { isExpired } from "react-jwt"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'



/*
    Widget Settings
*/
class Settings extends Component {


    constructor() {
        super()

        this.state = {
            name: '',
            birthday: '',
            gender: 0,
            phone: '',
            email: '',
            isSubscribe: 0,
            image: null,

            loading: true,
            network: false,
            error: false,
            waiting: false
        }
    }


    componentDidMount = () => {
        this.load()
    }


    /* Load Data */
    load = () => {

        const { token, navigate } = this.props

        if (!token || isExpired(token)) {
            navigate('/')
        }
        else {
            get(token).then(response => {
                if (response.status === 200) {

                    const data = response.data

                    this.setState({
                        name: utils.check(data.name),
                        phone: utils.check(data.phone),
                        birthday: utils.check(data.birthday),
                        gender: data.gender ? data.gender : 0,
                        email: utils.check(data.email),
                        isSubscribe: parseInt(data.isSubscribe),
                        image: data.image ? data.image : null,
                        error: false,
                        network: false
                    })
                }
                else {
                    this.setState({ error: true })
                }
            }).catch(() => {
                this.setState({ network: true })
            }).finally(() => {
                this.setState({ loading: false })
            })
        }

    }


    /* Save Data */
    save = () => {

        const { name, phone, birthday, gender, isSubscribe } = this.state
        const { token, setToken } = this.props

        if (name === '') {
            swal({ icon: 'error', title: 'Ошибка!', text: 'Введите свое имя', buttons: false, timer: 1500 })
            return
        }

        this.setState({ waiting: true })

        edit(token, { name, phone, birthday, gender, isSubscribe }).then(response => {
            if (response.status === 200) {
                setToken(response.data.token)
                swal({ icon: 'success', title: 'Данные успешно сохранены', text: ' ', buttons: false, timer: 1500 })
            }
            else {
                swal({ icon: 'error', title: 'Ошибка!', text: 'Что-по пошло не так! Попробуйте позднее', buttons: false, timer: 1500 })
            }
        }).catch(() => {
            swal({ icon: 'error', title: 'Ошибка!', text: 'Что-по пошло не так! Попробуйте позднее', buttons: false, timer: 1500 })
        }).finally(() => {
            this.setState({ waiting: false })
        })
    }


    /* Upload Image */
    uploadFile = event => {

        const { token, setToken } = this.props
        const file = event.target.files[0]

        if (file) {

            const data = new FormData()
            data.append('image', file)

            upload(token, data).then(response => {
                if (response.status === 200) {
                    this.setState({ image: response.data.image })
                    setToken(response.data.token)
                    swal({ icon: 'success', title: 'Изображение успешно сохранено', text: ' ', buttons: false, timer: 1500 })
                }
                else {
                    swal({ icon: 'error', title: 'Ошибка!', text: 'Что-по пошло не так! Попробуйте позднее', buttons: false, timer: 1500 })
                }
            }).catch(() => {
                swal({ icon: 'error', title: 'Ошибка!', text: 'Что-по пошло не так! Попробуйте позднее', buttons: false, timer: 1500 })
            })
        }
    }


    render = () => {

        const { name, birthday, gender, phone, email, isSubscribe, image, loading, network, error, waiting } = this.state

        if (loading) {
            return (
                <div className="profile">
                    <div className="profile-wrapper">
                        <div className="profile-container">
                            <Menu page="profile" />
                            <div className="profile-box">
                                <Loading />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (network) {
            return (
                <div className="profile">
                    <div className="profile-wrapper">
                        <div className="profile-container">
                            <Menu page="profile" />
                            <div className="profile-box">
                                <Network reload={() => this.load()} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (error) {
            return (
                <div className="profile">
                    <div className="profile-wrapper">
                        <div className="profile-container">
                            <Menu page="profile" />
                            <div className="profile-box">
                                <Network error reload={() => this.load()} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }


        return (
            <div className="profile">
                <div className="profile-wrapper">
                    <div className="profile-container">

                        <Menu page="profile" />

                        <div className="profile-box">

                            <div className="profile-form">

                                <h2>Личные данные</h2>

                                <div className="profile-form-row ava">
                                    <p>Ава</p>

                                    {image
                                        ? <label className="profile-form-image">
                                            <input onChange={event => this.uploadFile(event)} type="file" name="file" accept="image/png, image/jpeg" />
                                            <div className="profile-form-edit-button">
                                                <img src="/images/image.png" alt="Add" />
                                            </div>
                                            <div className="profile-form-image-box">
                                                <img alt="User" src={`${env.mediapoint}users/${image}`} />
                                            </div>
                                        </label> :
                                        <label className="profile-form-image">
                                            <input onChange={event => this.uploadFile(event)} type="file" name="file" accept="image/png, image/jpeg" />
                                            <div className="profile-form-image-button">
                                                <img src="/images/image.png" alt="Add" />
                                            </div>
                                        </label>
                                    }

                                </div>

                                <div className="profile-form-row">
                                    <p>Имя</p>
                                    <input value={name} onChange={event => this.setState({ name: event.target.value })} placeholder="Введите свое имя" />
                                </div>

                                <div className="profile-form-row">
                                    <p>Дата рождения</p>
                                    <InputMask mask="99.99.9999" value={birthday} onChange={event => this.setState({ birthday: event.target.value })} placeholder="Введите дата рождения" />
                                </div>

                                <div className="profile-form-row">
                                    <p>Пол</p>
                                    <select value={gender} onChange={event => this.setState({ gender: event.target.value })}>
                                        <option value="0">Не выбран</option>
                                        <option value="1">Мужской</option>
                                        <option value="2">Женский</option>
                                    </select>
                                </div>

                                <div className="profile-form-row">
                                    <p>Номер телефона</p>
                                    <InputMask mask="+7 (999) 999-99-99" value={phone} onChange={event => this.setState({ phone: event.target.value })} placeholder="Введите телефон номер" />
                                </div>

                                <div className="profile-form-row">
                                    <p>Эл. Почта</p>
                                    <div className="profile-form-email">
                                        {email}
                                        <img src="/images/lock.png" alt="Lock" />
                                    </div>
                                </div>

                                <div className="profile-form-checkbox">
                                    <input checked={isSubscribe === 1} value={isSubscribe} onChange={event => this.setState({ isSubscribe: event.target.checked ? 1 : 0 })} id="isSubscribe" type="checkbox" />
                                    <label htmlFor="isSubscribe">Подписаться на рассылку</label>
                                </div>

                                <div onClick={() => this.save()} className="profile-form-button">
                                    {waiting ? <LoadingOutlined /> : "Сохранить"}
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        token: state.token
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setToken: data => dispatch({ type: 'ADD_TOKEN', payload: data })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Settings))