import React, { Component } from "react"

/* Components */
import { Empty } from '../../components'
import Product from "./Product"


/* Page Favourite */
class Products extends Component {

    productsContainerRef = React.createRef();

    componentDidUpdate(prevProps) {

        // Scroll to the products container when the category changes
        if (prevProps.categoryName !== this.props.categoryName) {
            this.productsContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }
    render = () => {

        const { products, categoryName, restaurant, onCategoryChange } = this.props

        if (products.length === 0) {
            return (
                <div className="products">
                    <div className="products-wrapper">
                        <Empty page="products" />
                    </div>
                </div>
            )
        }

        return (
            <div className="products-container">
                <div className="products-wrapper">
                    <p className="products-name">{categoryName}</p>
                    <div className="products">
                        {products.map((item, index) => <Product key={`${index}`} restaurant={restaurant} product={item} onClick={() => onCategoryChange(index)} />)}
                    </div>
                </div>
            </div>

        )
    }

}


export default Products