import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import reportWebVitals from './reportWebVitals'

/* CSS */
import './design/css/app.css'
import './design/css/header.css'
import './design/css/footer.css'
import './design/css/menu.css'
import './design/css/page404.css'
import './design/css/auth.css'
import './design/css/home.css'
import './design/css/slide.css'
import './design/css/restaurant.css'
import './design/css/restaurantview.css'
import './design/css/network.css'
import './design/css/profile.css'
import './design/css/mobile.css'
import './design/css/address.css'
import './design/css/order.css'
import './design/css/ordercreate.css'
import './design/css/orderview.css'
import './design/css/city.css'
import './design/css/product.css'
import './design/css/product-categories.css'
import './design/css/category-products.css'
import './design/css/gallery.css'
import './design/css/cart.css'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(<App />)

reportWebVitals()