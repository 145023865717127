/* City MODAL */
const city = (state = false, action) => {

    switch (action.type) {

        case 'TOGGLE_CITY':
            return action.payload

        default:
            return state
    }
    
}

export default city