import React, { Component } from "react"

class ProductCategory extends Component {

    render = () => {

        const { data, isActive, onClick } = this.props

        return (

            <div
                className={`product-category-item ${isActive ? 'active' : ''}`}
                onClick={onClick}
            >
                <span>{data.name}</span>
            </div>
        )
    }

}

export default ProductCategory