import React, { Component } from "react"

/* Helpers */
import { withRouter } from '../../helpers'

/* React Router */
import { Link } from "react-router-dom"

/* Connect */
import { connect } from 'react-redux'

/* Swal */
import swal from 'sweetalert'



/*
    Widget Menu
*/
class Menu extends Component {


    logout = () => {

        const { navigate, removeToken } = this.props

        swal({
            title: 'Выйти из аккаунта?',
            text: 'Вы дейсвительно хотите выйти?',
            icon: 'warning',
            buttons: ["Отменить", "Да, выйти!"],
            dangerMode: true,

        }).then(confirmed => {
            if (confirmed) {
                removeToken()
                navigate("/")
            }
        })
    }



    render = () => {

        const { page } = this.props

        return (
            <div className="profile-menu">
                <Link className={page === "profile" ? "active" : ""} to="/profile">Профиль</Link>
                <Link className={page === "order" ? "active" : ""} to="/my-orders">Мои заказы</Link>
                <Link className={page === "address" ? "active" : ""} to="/address">Мои адреса</Link>
                <Link className={page === "password" ? "active" : ""} to="/change-password">Изменить пароль</Link>
                <div onClick={() => this.logout()} className="profile-menu-logout" to="/address">Выйти</div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        token: state.token
    }
}

const mapDispatchToProps = dispatch => {
    return {
        removeToken: () => dispatch({ type: 'REMOVE_TOKEN', payload: null })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu))