import React, { Component } from "react"

/* Components */
import { Loading, Network, Empty } from '../../components'

/* Widgets */
import Menu from './Menu'

/* JWT */
import { isExpired } from "react-jwt"

/* Helpers */
import { utils, withRouter } from '../../helpers'

/* Redux */
import { connect } from 'react-redux'

/* REST API */
import { list } from '../../api/Order'

/* Constants */
import { env } from "../../constants"

/* Moment */
import moment from "moment"
import 'moment/locale/ru'
moment.locale('ru')




/* Widget Order */
class Order extends Component {

    constructor() {
        super()

        this.state = {
            data: [],

            loading: true,
            network: false,
            error: false,
            width: 0
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }

    componentDidMount = () => {
        this.load()

        this.updateWindowDimensions()
        window.addEventListener('resize', this.updateWindowDimensions)

        window.scroll(0, 0)
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions)
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth });
    }

    /* Load Data */
    load = () => {

        const { token, navigate } = this.props

        if (!token || isExpired(token)) {
            navigate('/')
        }
        else {
            list(token).then(response => {
                if (response.status === 200) {
                    this.setState({ data: response.data, error: false, network: false })
                }
                else {
                    this.setState({ error: false })
                }
            }).catch(() => {
                this.setState({ network: true })
            }).finally(() => {
                this.setState({ loading: false })
            })
        }

    }



    goOrder = number => {

        const { navigate } = this.props
        const { width } = this.state

        if (width > 768) {
            navigate(`/order/${number}`)
        }
        else {
            navigate(`/account/order/${number}`)
        }
    }



    render = () => {

        const { data, loading, network, error } = this.state

        if (loading) {
            return (
                <div className="profile">
                    <div className="profile-wrapper">
                        <div className="profile-container">
                            <Menu page="order" />
                            <div className="profile-box">
                                <Loading />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (network) {
            return (
                <div className="profile">
                    <div className="profile-wrapper">
                        <div className="profile-container">
                            <Menu page="order" />
                            <div className="profile-box">
                                <Network reload={() => this.load()} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (error) {
            return (
                <div className="profile">
                    <div className="profile-wrapper">
                        <div className="profile-container">
                            <Menu page="order" />
                            <div className="profile-box">
                                <Network error reload={() => this.load()} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (data.length === 0) {
            return (
                <div className="profile">
                    <div className="profile-wrapper">
                        <div className="profile-container">
                            <Menu page="order" />
                            <div className="profile-box">
                                <Empty page="order" />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }


        return (
            <div className="profile">
                <div className="profile-wrapper">
                    <div className="profile-container">

                        <Menu page="order" />

                        <div className="profile-box">
                            <h2>Мои заказы</h2>

                            {data.map((item, index) => {

                                const products = item.products ? JSON.parse(item.products) : []

                                return (
                                    <div onClick={() => this.goOrder(item.number)} key={`${index}`} className="profile-order-item">

                                        <div className="profile-order-item-head">
                                            <div className="profile-order-item-head-left">
                                                <div className="profile-order-item-number">#{item.number}</div>
                                                <div className="profile-order-item-created">{moment(item.createdAt).format("Do MMMM YYYY, hh:mm")}</div>
                                            </div>
                                        </div>

                                        <div className="profile-order-item-box">

                                            <div className="profile-order-item-info">
                                                <p>{item.deliveryType === "delivery" ? "Доставка курьером" : "Способ доставки"}</p>
                                                <b>{item.deliveryType === "delivery" ? item.address : "Самовывоз"}</b>

                                                <p>Способ оплаты</p>
                                                <b>{item.paymentType === "cash" ? "Наличными" : "С картой"}</b>

                                                {item.deliveryType === "delivery" &&
                                                    <div>
                                                        <p>Стоимость доставки</p>
                                                        <b>{(item.delivery && parseInt(item.delivery) > 0) ? utils.convertor(item.delivery) : "Бесплатно"}</b>
                                                    </div>
                                                }

                                                <p>Сумма заказа</p>
                                                <b>{utils.convertor(item.total)}</b>

                                                <div className="order-tag-box">
                                                    {utils.deliveryStatus(item.deliveryType, item.deliveryStatus)}
                                                </div>

                                            </div>

                                            <div className="profile-order-item-products">
                                                {products.map((product, i) =>
                                                    <div className="profile-order-item-product" key={`product-${i}`}>

                                                        <div className="profile-order-item-product-info">
                                                            {product.image ? <img src={`${env.mediapoint}products/${product.image}`} alt="Product" /> : null}
                                                            <div className="profile-order-item-product-text">
                                                                <div className="profile-order-item-product-name">{product.product}</div>
                                                                <div className="profile-order-item-product-amount">{product.amount} шт</div>
                                                            </div>
                                                        </div>

                                                        <div className="profile-order-item-product-price-box">
                                                            <div className="profile-order-item-product-price">
                                                                {parseInt(product.sale) > 0 ? utils.convertor(parseInt(product.sale) * parseInt(product.amount)) : utils.convertor(parseInt(product.price) * parseInt(product.amount))}
                                                            </div>
                                                            <div className="profile-order-item-product-sale-price">
                                                                {parseInt(product.sale) > 0 ? utils.convertor(parseInt(product.price) * parseInt(product.amount)) : ""}
                                                            </div>
                                                        </div>

                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                )
                            })}

                        </div>

                    </div>
                </div>
            </div>
        )
    }
}



const mapStateToProps = state => {
    return {
        token: state.token
    }
}

export default connect(mapStateToProps)(withRouter(Order))