import React, { Component } from "react"

/* Widgets */
import { Restaurant } from '../Restaurant'


/* Widget Restaurants */
class Restaurants extends Component {

    render = () => {

        const { title, data } = this.props

        return (
            <div className="restaurants-container">
                <div className="restaurants-wrapper">
                    <h2>{title}</h2>

                    <div className="restaurants">
                        {data !== null && data !== undefined && Array.isArray(data) && data.length > 0 && data.map((item, index) =>
                            <Restaurant key={`${index}`} data={item} />
                        )}
                    </div>
                    
                </div>
            </div>
        )
    }

}

export default Restaurants