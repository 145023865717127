import React, { Component } from "react"

/* Widgets */
import { Menu } from '../../widgets'

/* Helpers */
import { withRouter } from '../../helpers'


/* 
    Page Mobile Contact
*/
class MobileContact extends Component {

    render = () => {

        const { navigate } = this.props

        return (
            <div className="mobile-account-page">

                <div className="account-header">
                    <div onClick={() => navigate(-1)} className="account-header-back">
                        <img src="/images/mobile/back.png" alt="Back" />
                    </div>
                    <h2>Контакты</h2>
                </div>

                <div className="mobile-account-container">

                    <div className="mobile-contact">
                        <div className="mobile-contact-item">

                            <h3>г. Алматы</h3>

                            <p>Телефон</p>
                            <b>+7 (747) 312-54-58</b>

                            <p>Вопросы, отзывы и предложения:</p>
                            <b>info@e-qr.kz</b>

                        </div>
                    </div>

                </div>

                <Menu />
            </div>
        )
    }

}

export default withRouter(MobileContact)