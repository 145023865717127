import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'


/* Component Loading */
class Loading extends Component {

    render = () => {
        return (
            <div className="loading">
                <LoadingOutlined />
            </div>
        )
    }

}

export default Loading