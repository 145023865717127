import React, { Component } from "react"

/* React Router */
import { Link } from "react-router-dom"

/* Constants */
import { env } from "../../constants"

/* Helpers */
import { utils } from '../../helpers'

/* Redux */
import { connect } from 'react-redux'

/* Swal */
import Swal from 'sweetalert2'

/* Page product */
class Product extends Component {

    /* Add to Cart */
    add = () => {
        /* Fields */
        const { product, restaurant, add } = this.props

        /* Calculate ions */
        const payload = { restaurant: { id: restaurant.id, name: restaurant.name }, product: { ...product, amount: 1 } }

        /* SAVE IN STATE */
        add(payload)

        /* Notification */
        Swal.fire({
            html: `<p class="notification">Успешно добавлено в корзину</p>`,
            color: 'white',
            background: 'black',
            showConfirmButton: false,
            position: 'bottom',
            timer: 1000,
            backdrop: false,
            width: 300
        })
    }

    /* Plus */
    plus = amount => {
        /* Fields */
        const { product, restaurant, update } = this.props

        /* Calculations */
        const count = amount + 1
        const payload = { updateRestaurant: { id: restaurant.id, name: restaurant.name }, updateProduct: { ...product, amount: count } }

        /* SAVE IN STATE */
        update(payload)
    }


    /* Minus */
    minus = amount => {

        /* Fields */
        const { product, restaurant, update, remove } = this.props

        /* Calculations */
        const count = amount - 1
        
        /* SAVE IN STATE */
        if (count === 0) {
            const payload = { removeRestaurant: { id: restaurant.id, name: restaurant.name }, removeProduct: { ...product, amount: count } }
            remove(payload)
        }
        else {
            const payload = { updateRestaurant: { id: restaurant.id, name: restaurant.name }, updateProduct: { ...product, amount: count } }
            update(payload)
        }
    }

    /* Draw BUTTON */
    _button = () => {

        /* Fields */
        const { cart, product, restaurant } = this.props
        
        let amount = 0

        /* FIND PRODUCT AMOUNT IN CART */
        if (product && Array.isArray(cart) && cart.length > 0) {
            const restaurantIndex = cart.findIndex(item => parseInt(item.id) === parseInt(restaurant.id));
        
            if (restaurantIndex > -1) {
                const productsArray = cart[restaurantIndex].products;
        
                if (Array.isArray(productsArray) && productsArray.length > 0) {
                    const productIndex = productsArray.findIndex(item => parseInt(item.id) === parseInt(product.id));
        
                    if (productIndex > -1) {
                        amount = productsArray[productIndex]?.amount || 0;
                    }
                }
            }
        }
        

        /* DISPLAY BUTTON WHEN AMOUNT IS ZERO */
        if (amount === 0) {
            return (
                <div onClick={() => this.add()} className="product-bottom-cart">
                    {/* <div className="product-bottom-cart"> */}
                    Добавить
                    {/* </div> */}
                </div>
            )
        }

        /* DISPLAY COUNTER BUTTONS */
        return (
            <div className="product-bottom-cart product-bottom-card-amount">

                {/* Minus */}
                <div onClick={() => this.minus(amount)} className="product-card-minus-button">
                    <img src="/images/minus.png" alt="Minus" />
                </div>

                {amount}

                {/* Plus */}
                <div onClick={() => this.plus(amount)} className="product-card-plus-button">
                    <img src="/images/plus.png" alt="Plus" />
                </div>

            </div>
        )

    }

    render = () => {

        const { product } = this.props

        return (

            <div className="product">

                {product.image ? <img src={`${env.mediapoint}products/${product.image}`} alt={product.title} /> : null}

                <div className={`product-shadow-content ${product.image ? 'product-shadow-content-minimize' : ''}`}>
                    <div className="product-shadow-content-info">
                        <p>{product.name}</p>
                        <span>{product.tagline}</span>
                    </div>
                    <div className="product-bottom">
                        <div className="product-bottom-price">
                            {utils.convertor(product.price)}
                        </div>
                        {this._button()}
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        cart: state.cart,
        token: state.token
    }
}

const mapDispatchToProps = dispatch => {
    return {
        add: data => dispatch({ type: 'ADD_TO_CART', payload: data }),
        update: data => dispatch({ type: 'UPDATE_DATA', payload: data }),
        remove: data => dispatch({ type: 'REMOVE_FROM_CART', payload: data }),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Product)