import React, { Component } from "react"

/* Helpers */
import { withRouter } from '../helpers'

/* Redux */
import { connect } from 'react-redux'

/* Router */
import { NavLink } from "react-router-dom"

/* JWT */
import { isExpired, decodeToken } from "react-jwt"

/* Constants */
import { env } from "../constants"


/* Widget Menu */
class Menu extends Component {


    /* Draw User */
    _user = () => {

        const { token, toggle } = this.props

        if (!token || isExpired(token)) {
            return (
                <div onClick={() => toggle(true)} className="mobile-menu-item">
                    <img src="/images/mobile/profile.png" alt="Профиль" />
                    <p>Профиль</p>
                </div>
            )
        }

        const user = token ? decodeToken(token) : null

        return (
            <NavLink to="/account" className="mobile-menu-item">

                {(user && user.image)
                    ? <img src={`${env.mediapoint}users/${user.image}`} alt="Профиль" className="ava" />
                    : <img src="/images/mobile/profile.png" alt="Профиль" />
                }

                <p className="username">{(user && user.name) ? user.name : "Профиль"}</p>

            </NavLink>
        )


    }



    /* Draw Favourite */
    _favourite = () => {

        const { token, toggle } = this.props

        if (!token || isExpired(token)) {
            return (
                <div onClick={() => toggle(true)} className="mobile-menu-item">
                    <img src="/images/mobile/heart.png" alt="Избранное" />
                    <p>Избранное</p>
                </div>
            )
        }

        return (
            <NavLink to="/favourite/mobile" className="mobile-menu-item">
                <img src="/images/mobile/heart.png" alt="Избранное" />
                <p>Избранное</p>
            </NavLink>
        )

    }


    render = () => {

        const { cart } = this.props

        return (
            <div className="mobile-menu">

                <NavLink to="/" className="mobile-menu-item">
                    <img src="/images/mobile/home.png" alt="Главная" />
                    <p>Главная</p>
                </NavLink>

                <NavLink to="/scan" className="mobile-menu-item">
                    <img src="/images/mobile/scan.png" alt="QR меню" />
                    <p>QR меню</p>
                </NavLink>

                <NavLink to="/cart" className="mobile-menu-item">
                    <img src="/images/mobile/cart.png" alt="Корзина" />
                    <p>Корзина</p>
                    {cart.length > 0 && <span>{cart.length}</span>}
                </NavLink>

                {/* {this._favourite()} */}
                {this._user()}

            </div>
        )
    }

}



const mapStateToProps = state => {
    return {
        token: state.token,
        cart: state.cart,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggle: data => dispatch({ type: 'TOGGLE_AUTH', payload: data }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu))
