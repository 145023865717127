import React, { Component } from "react"

/* Router */
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

/* Redux */
import { Provider } from 'react-redux'
import { store } from './store'

/* Pages */
import { NotFound, Home, Cart, Restaurant, RestaurantSearch, Search, Category, Favourite, Profile, MyOrders, OrderView, Address, Password } from './pages'
import { Account, MobileProfile, MobileOrders, MobileOrder, MobileAddress, MobileContact, MobilePassword, MobileFavourite } from './pages/Account'
import { Order } from "./pages/Order"

/* Widgets */
import { Authorization, City } from './widgets'



/* Entry point */
class App extends Component {

    render = () => {
        return (
            <Provider store={store}>
                
                <Router>
                    <Routes>

                        <Route path="*" element={<NotFound />} />

                        <Route path="/" element={<Home />} />
                        <Route path="/search/:slug" element={<Search />} />
                        <Route path="/restaurant/:slug" element={<Restaurant />} />
                        <Route path="/favourite" element={<Favourite />} />
                        <Route path="/category/:slug" element={<Category />} />

                        {/* profile */}
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/address" element={<Address />} />
                        <Route path="/my-orders" element={<MyOrders />} />
                        <Route path="/order/:number" element={<OrderView />} />
                        <Route path="/change-password" element={<Password />} />

                        {/* Mobile Screens */}
                        <Route path="/account" element={<Account />} />
                        <Route path="/account/profile" element={<MobileProfile />} />
                        <Route path="/account/orders" element={<MobileOrders />} />
                        <Route path="/account/order/:number" element={<MobileOrder />} />
                        <Route path="/account/address" element={<MobileAddress />} />
                        <Route path="/account/contact" element={<MobileContact />} />
                        <Route path="/account/password" element={<MobilePassword />} />
                        <Route path="/favourite/mobile" element={<MobileFavourite />} />

                        <Route path="/cart" element={<Cart />} />

                        {/* Order */}
                        <Route path="/order" element={<Order />} />

                    </Routes>

                    {/* Authorization Modal */}
                    <Authorization />

                    {/* City Modal */}
                    <City />

                </Router>
            </Provider>
        )
    }

}

export default App