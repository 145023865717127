import React, { Component } from "react"

/* Widget Restaurant Header */
class Search extends Component {

    constructor(props) {
        super(props)

        const { text } = props
        let search = ''

        if (text) {
            search = text
        }

        this.state = {
            search,
            loading: false
        }
    }

    /* Go To Search */
    goSearch = () => {
        const { navigate, data } = this.props
        const { search } = this.state

        if (search !== "") {
            // Use template literals to construct the URL with the search query
            // <Link to={`/restaurant/${data.slug}`} className="restaurant" style={{ width: size, height: size / 1.5 }}></Link>
            navigate(`/restaurant/${data.slug}?search=${encodeURIComponent(search)}`)
        }
    }

    render = () => {
        const { data, search, setSearch } = this.props

        return (
             // HEADER SEARCH
            <div className="mobile-restaurant-search">
                <div className="mobile-restaurant-search-button">
                    <img src="/images/search-black.png" alt="Search"/>
                </div>
                <input value={search} onChange={event => setSearch(event.target.value)}
                       placeholder={`Искать в ${data.name}`}/>
            </div>
        )
    }

}

export default Search