import React, { Component } from "react"

/* Helpers */
import { withRouter } from '../../helpers'

/* Redux */
import { connect } from 'react-redux'

/* Widgets */
import { Restaurant } from "../Restaurant"

/* Components */
import { Empty } from '../../components'




/* Page Favourite */
class Favourite extends Component {

    render = () => {

        const { favourites } = this.props

        if (favourites.length === 0) {
            return (
                <div className="favourite">
                    <div className="favourite-wrapper">
                        <Empty page="favourite" />
                    </div>
                </div>
            )
        }

        return (
            <div className="restaurants-container">
                <div className="restaurants-wrapper">
                    <h2>Избранное</h2>

                    <div className="restaurants">
                        {favourites.map((item, index) =>
                            <Restaurant key={`${index}`} data={item} />
                        )}
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        favourites: state.favourites,
    }
}

export default connect(mapStateToProps)(withRouter(Favourite))