import React, { Component } from "react"

/* React Router */
import { Link } from "react-router-dom"

/* Components */
import { Text } from '../components'



/*
    Widget Footer
*/
class Footer extends Component {


    constructor() {
        super()

        this.state = {
            width: 0
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }


    componentDidMount = () => {
        this.updateWindowDimensions()
        window.addEventListener('resize', this.updateWindowDimensions)
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions)
    }


    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth });
    }


    render = () => {

        const { width } = this.state

        return (
            <div className="footer">
                <div className="footer-wrapper">

                    {/* FOOTER BOX */}
                    <div className="footer-box">

                        <div className="footer-item footer-main">

                            {/* FOOTER LOGO */}
                            <div className="footer-logo">
                                <Link to="/">
                                    <img src="/images/logo.png" alt="E-QR" />
                                    <Text color="#666" size={14} top={5}>Электронное QR меню</Text>
                                </Link>
                            </div>

                            <div className="footer-info">
                                <b> ТОО «Sary Group»</b>
                            </div>

                            <div className="footer-info">
                                <b>БИН: 240440023071</b>
                            </div>

                            <div className="footer-info">
                                <img src="/images/footer/home.png" alt="Home" />
                                <b>Казахстан, г.Алматы, Медеуский район, улица Рубинштейна, 52</b>
                            </div>

                            <div className="footer-info">
                                <img src="/images/footer/mail.png" alt="Mail" />
                                <b>info@e-qr.kz</b>
                            </div>

                        </div>

                        <div className="footer-item">
                            <span>Категории</span>
                            <Link>Рестораны</Link>
                            <Link>Фастфуды</Link>
                            <Link>Кофейня</Link>
                            <Link>Караоке</Link>
                        </div>

                        <div className="footer-item">
                            <span>Полезные ссылки</span>
                            <Link>Главная</Link>
                            <Link to="/about">О нас</Link>
                            <Link to={width > 748 ? '/contact' : '/account/contact'}>Контакты</Link>
                        </div>

                        <div className="footer-item">

                            <span>Связаться с нами</span>

                            <div className="footer-item-contact">
                                <img src="/images/footer/phone.png" alt="Phone" />
                                <div className="footer-item-contact-name">
                                    <div className="footer-item-contact-text">24/7 Техподдержка</div>
                                    <div className="footer-item-contact-value">+7 (747) 599-40-41</div>
                                </div>
                            </div>
                            <div className="footer-item-contact">
                                <img src="/images/footer/mail.png" alt="Mail" />
                                <div className="footer-item-contact-name">
                                    <div className="footer-item-contact-text">Email адрес:</div>
                                    <div className="footer-item-contact-value">info@e-qr.kz</div>
                                </div>
                            </div>

                        </div>

                    </div>


                    {/* COPY BOX */}
                    <div className="copy">
                        <p>© 2023 ТОО «Sary Group» Все права защищены.</p>
                        <img src="/images/footer/visa.png" alt="Visa" />
                        <div className="social-network">
                            <p>Оставайся на связи:</p>
                            <div className="social-network-box">
                                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/eqr.kz/"><img src="/images/social/instagram.png" alt="Instagram" /></a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

}

export default Footer