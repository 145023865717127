import React, {Component} from "react"

/* Helpers */
import {withRouter, utils} from '../../helpers'

/* Redux */
import {connect} from 'react-redux'

/* Swal */
import Swal from 'sweetalert2'

/* JWT */
import {isExpired} from "react-jwt"

/* Components */
import {Empty} from "../../components"
import {CartInfo} from "./index";
import {env} from "../../constants";
import {DeleteOutlined} from "@ant-design/icons";


/* Widget Basket */
class Basket extends Component {


    constructor() {
        super()

        this.state = {
            width: 0
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }


    componentDidMount = () => {
        this.updateWindowDimensions()
        window.addEventListener('resize', this.updateWindowDimensions)
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions)
    }


    updateWindowDimensions = () => {
        this.setState({width: window.innerWidth});
    }


    /* Clear action */
    clear = (restaurant) => {

        const {clear} = this.props

        Swal.fire({
            title: 'Очистить продукты ' + restaurant.name + '?',
            text: 'Вы дейсвительно хотите очистить?',
            icon: 'warning',
            confirmButtonText: 'Да, очистить!',
            cancelButtonText: 'Отменить',
            showCancelButton: true,
            confirmButtonColor: '#FFAB22',
            reverseButtons: true
        }).then(result => {
            if (result.isConfirmed) {
                const payload = {
                    clearRestaurant: {id: restaurant.id, name: restaurant.name}
                }
                clear(payload)
                Swal.fire({
                    html: `<p class="notification">Успешно очищена</p>`,
                    color: 'white',
                    background: 'black',
                    showConfirmButton: false,
                    position: 'bottom-left',
                    timer: 1000,
                    backdrop: false,
                    width: 300
                })
            }
        })
    }


    /* Remove action */
    remove = (restaurant, item) => {
        const {remove} = this.props

        const payload = {
            removeRestaurant: {id: restaurant.id, name: restaurant.name},
            removeProduct: {...item, amount: 0}
        }
        remove(payload)
    }

    /* Minus action */
    minus = (restaurant, item) => {
        const {update, remove} = this.props
        const count = parseInt(item.amount) - 1

        /* SAVE IN STATE */
        if (count === 0) {
            const payload = {
                removeRestaurant: {id: restaurant.id, name: restaurant.name},
                removeProduct: {...item, amount: count}
            }
            remove(payload)
        } else {
            const payload = {
                updateRestaurant: {id: restaurant.id, name: restaurant.name},
                updateProduct: {...item, amount: count}
            }
            update(payload)
        }
    }

    /* Plus action */
    plus = (restaurant, item) => {
        const {update} = this.props
        const count = parseInt(item.amount) + 1
        const payload = {
            updateRestaurant: {id: restaurant.id, name: restaurant.name},
            updateProduct: {...item, amount: count}
        }

        /* SAVE IN STATE */
        update(payload)
    }


    /* Go To Order */
    goOrder = () => {

        const {token, toggle, navigate} = this.props

        if (!token || isExpired(token)) {
            toggle(true)
        } else {
            navigate('/order')
        }

    }


    /* Draw total */
    _total = () => {

        const {cart} = this.props

        let total = 0

        cart.forEach(item => {
            item.products.forEach(product => {
                const price = parseInt(product.price)
                total += price * parseInt(product.amount)
            })
        })

        return (
            <div className="cart-total">
                <h3>Ваш заказ</h3>
                <div className="cart-total-row">
                    <p>Товары ({cart.length})</p>
                    <p>{utils.convertor(total)}</p>
                </div>
                <div className="cart-total-row">
                    <b>Итого</b>
                    <b>{utils.convertor(total)}</b>
                </div>
                <div onClick={() => this.goOrder()} className="cart-total-button">Перейти к оформлению</div>
            </div>
        )
    }


    render = () => {

        const {cart, navigate} = this.props

        if (cart.length === 0) {
            return <Empty page="cart"/>
        }


        return (
            <div className="cart">
                <div className="cart-wrapper">

                    <div className="cart-title">
                        <div onClick={() => navigate(-1)} className="cart-back">
                            <img src="/images/back.png" alt="Back"/>
                        </div>
                        <h2>Корзина</h2>
                    </div>

                    <div className="cart-body">
                        <div className="cart-restaurant">
                            {cart.map((restaurant, idx) =>
                                <div className='cart-info' key={`${idx}`}>
                                    <div className="cart-restaurant-title">
                                        {restaurant.name}
                                    </div>
                                    <div className="cart-box">
                                        <div className="cart-products">
                                            <div className="cart-clear">
                                                <div className="cart-clear-button" onClick={() => this.clear(restaurant)}>
                                                    Удалить все
                                                </div>
                                            </div>
                                            {restaurant.products.map((item, index) =>
                                                <div key={`${index}`} className="cart-product-item">
                                                    {item.image && (
                                                        <img
                                                            src={`${env.mediapoint}/images/products/${item.image}`}
                                                            alt={item.name}
                                                        />
                                                    )}
                                                    <div className="cart-product-item-right">
                                                        <p>{item.name}</p>
                                                        <div className="cart-product-item-controller">
                                                            <div className="cart-product-counter">
                                                                <div onClick={() => this.minus(restaurant, item)}
                                                                     className="cart-product-minus"
                                                                     style={parseInt(item.amount) === 1 ? {opacity: 0.5} : {}}>
                                                                    <img src="/images/minus-filled.png" alt="Minus"/>
                                                                </div>
                                                                <div className="cart-product-amount">{item.amount}</div>
                                                                <div onClick={() => this.plus(restaurant, item)}
                                                                     className="cart-product-plus">
                                                                    <img src="/images/plus-filled.png" alt="Plus"/>
                                                                </div>
                                                            </div>
                                                            <div className="cart-product-price-box">
                                                                <div
                                                                    className="cart-product-price">{utils.convertor(parseInt(item.price) * parseInt(item.amount))}</div>
                                                            </div>
                                                            <div onClick={() => this.remove(restaurant, item)}
                                                                 className="cart-product-remove">
                                                                <DeleteOutlined/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="cart-box-total">
                            {this._total()}
                        </div>

                    </div>
                </div>


                <div className="cart-box-mobile-bottom">
                    <div onClick={() => this.goOrder()} className="cart-box-mobile-button">Перейти к оформлению</div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        cart: state.cart,
        token: state.token
    }
}


const mapDispatchToProps = dispatch => {
    return {
        add: data => dispatch({type: 'ADD_TO_CART', payload: data}),
        update: data => dispatch({type: 'UPDATE_DATA', payload: data}),
        refresh: data => dispatch({type: 'REFRESH_DATA', payload: data}),
        remove: data => dispatch({type: 'REMOVE_FROM_CART', payload: data}),
        clear: data => dispatch({type: 'CLEAR_RESTAURANT', payload: data}),
        toggle: data => dispatch({type: 'TOGGLE_AUTH', payload: data}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Basket))