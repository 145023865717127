import React, { useEffect, useState } from "react"

/* Swipeable */
import { useSwipeable } from 'react-swipeable'


/* Constants */
import { env } from "../../constants"

/* Constants */
const WRAPPER = 1220
const MULTIPLIER = 2.25

/* Page gallery */
const Galleries = ({ data }) => {

    /* Hooks */
    const [width, setWidth] = useState(window.innerWidth)
    const [index, setIndex] = useState(0)
    const [paused, setPaused] = useState(false)


    /* Swipe Handlers */
    const handlers = useSwipeable({
        onSwipedLeft: () => updateIndex(index + 1, width),
        onSwipedRight: () => updateIndex(index - 1, width),
    })


    /* Window Resize Events and Auto Play */
    useEffect(() => {

        const interval = setInterval(() => {
            if (!paused) {
                updateIndex(index + 1, width)
            }
        }, 5000)

        window.addEventListener('resize', updateWindowDimensions)

        return () => {

            if (interval) {
                clearInterval(interval)
            }

            window.removeEventListener('resize', updateWindowDimensions)
        }

    })


    /* Window Resize Handler */
    const updateWindowDimensions = () => {
        setWidth(window.innerWidth)
    }


    /* Change Carousel Index */
    const updateIndex = (i, screenWidth) => {

        if (Array.isArray(data) && data.length > 3) {

            if (screenWidth <= 768) {
                if (i < 0) {
                    i = data.length - 1
                }
                else if (i >= data.length) {
                    i = 0
                }
            }
            else {
                if (i < 0) {
                    i = data.length - 3
                }
                else if (i > data.length - 3) {
                    i = 0
                }
            }

            setIndex(i)

        }
    }


    let start = 0
    let carouselWidth = width / 3

    if (width > WRAPPER) {
        carouselWidth = WRAPPER / 3
        start = (width - WRAPPER) / 2
    }

    if (width <= 768) {
        carouselWidth = width - 40
        start = 20
    }

    return (
        <div className="galleries-container" {...handlers} onMouseEnter={() => setPaused(true)} onMouseLeave={() => setPaused(false)}>
            <div className="galleries-wrapper" style={{transform: `translateX(${start - carouselWidth * index}px)`}}>
                {Array.isArray(data) && data.length > 0 && (
                    <p>Галерея</p>
                )}

                {Array.isArray(data) && data.length > 0 && data.map((item, index) =>
                    <div className="home-slide-item" style={{width: carouselWidth, height: carouselWidth / MULTIPLIER}}
                         key={`${index}`}>
                        <div className="home-slide-item-inner">
                            {item.image ?
                                <img src={`${env.mediapoint}galleries/${item.image}`} alt="Slide"/> : null}
                        </div>
                    </div>
                )}
            </div>

            {Array.isArray(data) && data.length > 2 &&
                <div onClick={() => updateIndex(index + 1, width)} className="home-slide-arrow" style={{ top: carouselWidth / (MULTIPLIER * 2) + 70, right: start - 5 }}>
                    <img src="/images/arrowleft.png" alt="Arrow" />
                </div>
            }

            {Array.isArray(data) && data.length > 2 &&
                <div onClick={() => updateIndex(index - 1, width)} className="home-slide-arrow" style={{ top: carouselWidth / (MULTIPLIER * 2) + 70, left: start - 5 }}>
                    <img src="/images/arrowright.png" alt="Arrow" />
                </div>
            }

        </div>
    )
}

export default Galleries